import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { ISubscription } from 'rxjs/Subscription';
import 'slick-carousel';
import { AppComponent } from '../../app.component';
import { ChatService } from '../../service/chat.service';
import { HttpApiService } from '../../service/http-api/http-api.service';
import { PhotoService } from '../../service/photo.service';
import { UserdataService } from '../../service/userdata.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit, OnDestroy {
  spinner = true;
  spinnerAva = true;
  spinnerPic = true;

  // user session
  userSession = localStorage.getItem('userSession');
  // user data
  userData = JSON.parse(localStorage.getItem('user'));

  subscription: ISubscription;

  // user name
  userName = '';
  // user city
  userCity = '';
  // user age
  userAge = '';
  // user zodiac
  userZodiac = '';
  // user avatar
  userAvatar = '';
  // user avatar large
  userAvatarLarge = '';
  // user photos
  userPhotos: any;

  // user purpose
  userPurpose = '';
  // user marital
  userMarital = '';
  // user children
  userChildren = '';
  // user languages
  userLanguages = '';
  // user smoking
  userSmoking = '';
  // user alcohol
  userAlcohol = '';
  // user education
  userEducation = '';
  // user height
  userHeight = '';
  // user weight
  userWeight = '';
  // user body
  userBody = '';
  // user eyes
  userEyes = '';
  // user hair
  userHair = '';
  // user bio
  userBio = '';
  // user hobbies
  userHobbies = '';

  avatarPressed = false;
  photoViewerOpen = false;
  photoGalleryPressed = false;
  userLanguagesPlaceholder = false;
  userBioPlaceholder = false;
  userHobbiesPlaceholder = false;
  userAvatarExist = false;

  UGMenuShow = false;
  photoIDcount = '';
  photoID = '';
  photoFace = true;
  avatarID = '';
  bodysRu;
  bodysKz;

  widthStyle: any;
  heightStyle: any;
  marginLeftStyle: any;
  marginTopStyle: any;
    public browserLang: any;

  // Нахождение склонения существительного после возраста пользователя
  checkAge(userAge, agePostfix) {
    const cases = [2, 0, 1, 1, 1, 2];
    return userAge + ' ' + agePostfix[ (userAge % 100 > 4 && userAge % 100 < 20) ? 2 : cases[(userAge % 10 < 5) ? userAge % 10 : 5] ];
  }

  avatarSpinner() {
    this.spinnerAva = false;
  }

  pictureSpinner() {
    this.spinnerPic = false;
  }

  constructor(
    private userdataService: UserdataService,
    private photoService: PhotoService,
    private elRef: ElementRef,
    private router: Router,
    public appComponent: AppComponent,
    private translate: TranslateService,
    private api: HttpApiService,
    private socket: ChatService
  ) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
      this.appComponent.currentUrl = '';

      this.bodysRu = {
          "settings": {
              "lang": "0"
          }
      };

      this.bodysKz = {
          "settings": {
              "lang": "3"
          }
      };

      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
          this.appComponent.flagLanguage = 'ru';
          this.api.post('user/edit?session=' + this.userSession, {data: this.bodysRu});
      } else {
          this.translate.use(this.browserLang = 'kz');
          this.appComponent.flagLanguage = 'kz';
          this.api.post('user/edit?session=' + this.userSession, {data: this.bodysKz});
      }

      this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {
        if (udRes['errors']) {
        } else if (udRes['meta'].user) {
          // Скрываем спиннер
          this.spinner = false;

          // Обновляем сессию в Local Storage
          this.userSession = udRes['meta'].user.session;
          localStorage.setItem('userSession', this.userSession);

          // user data
          this.userData = udRes['meta'].user;

          // Обновляем пользователя в Local Storage
          localStorage.setItem('user', JSON.stringify(udRes['meta'].user));

          // ПОДКЛЮЧАЕМ СОКЕТ
          this.socket.message$(this.userSession);

          // this.socket.disconnect();
          // this.appComponent.ngOnInit();

            // Подписываемся на события сокета
          this.subscription = this.socket.messages.subscribe(wtf => {
            // console.log(wtf.event);
            // console.log(wtf.data);
            if ( wtf.event === 'err_session_required' ) {
              console.log('WEBSOCKET : нет сессии!');
            }
            if ( wtf.event === 'err_unauthorized' ) {
              console.log('WEBSOCKET : соединение НЕ установлено!');
            }
            if ( wtf.event === 'success_authorized' ) {
              console.log('WEBSOCKET : установлено соединение!');
            }
            if ( wtf.event === 'ping' ) {
              this.socket.send({'event': 'pong', 'data': ''});
              console.log('WEBSOCKET : получен ПИНГ!');
            }
            if ( wtf.event === 'new_message' ) {
              console.log('WEBSOCKET : получено СООБЩЕНИЕ!');
              console.log(wtf.data);
            }
          });



          // user name
          this.userName = this.userData.profile.name;
          // user city
          this.userCity = this.userData.profile.city.title;
          // user age
          this.userAge = this.userData.profile.age;
            if (this.appComponent.flagLanguage === 'kz') {
                this.userAge = this.checkAge(this.userAge, ['Жас', 'Жас', 'Жас']);
            } else {
                this.userAge = this.checkAge(this.userAge, ['год', 'года', 'лет']);
            }
          // user zodiac
          this.userZodiac = this.userData.profile.zodiac.title;
          // user avatar
          if (this.userData.profile.avatar && this.userData.profile.avatar.face) {
            this.userAvatarExist = true;
            this.avatarID = this.userData.profile.avatar.id;
            this.userAvatar = this.userData.profile.avatar.preview_url + '&w=260';
            this.userAvatarLarge = this.userData.profile.avatar.url;
          } else {
            this.userAvatarExist = false;
            this.userAvatar = './assets/img/icons/sampleman.jpg';
          }
          // user photos
            this.userPhotos = this.userData.profile.photos;

          // user purpose
          this.userPurpose = this.userData.profile.purpose.title;
          // user marital
          this.userMarital = this.userData.profile.marital.title;
          // user children
          this.userChildren = this.userData.profile.children.title;
          // user languages
          if (!this.userData.profile.languages || this.userData.profile.languages.length === 0) {
            this.userLanguagesPlaceholder = true;
          } else {
            this.userLanguages = this.userData.profile.languages;
          }
          // user smoking
          this.userSmoking = this.userData.profile.smoking.title;
          // user alcohol
          this.userAlcohol = this.userData.profile.alcohol.title;
          // user education
          this.userEducation = this.userData.profile.education.title;
          // user height
          this.userHeight = this.userData.profile.height;
          // user weight
          this.userWeight = this.userData.profile.weight;
          // user body
          this.userBody = this.userData.profile.body.title;
          // user eyes
          this.userEyes = this.userData.profile.eyes.title;
          // user hair
          this.userHair = this.userData.profile.hair.title;
          // user bio
          if (this.userData.profile.bio) {
            this.userBio = this.userData.profile.bio;
          } else {
            this.userBioPlaceholder = true;
          }
          // user hobbies
          if (!this.userData.profile.hobbies || this.userData.profile.hobbies.length === 0) {
            this.userHobbiesPlaceholder = true;
          } else {
            this.userHobbies = this.userData.profile.hobbies;
          }
        }
      });
  }

  avatarOpen() {
    if (this.userAvatarExist === true) {
      this.photoViewerOpen = true;
      this.avatarPressed = true;
      $('body').css("overflow-y", "hidden");
      $('html').css("overflow-y", "hidden");
    }
  }
  avatarClose() {
    this.photoViewerOpen = false;
    this.avatarPressed = false;
    this.UGMenuShow = false;
    $('body').css("overflow-y", "");
    $('html').css("overflow-y", "");
  }

  avatarMenuToggle() {
    if (this.UGMenuShow === true) {
      this.UGMenuShow = false;
    } else {
      this.UGMenuShow = true;
    }
  }

    avatarMenuClose() {
        if (this.UGMenuShow === true) {
            this.UGMenuShow = false;
        }
    }

  galleryOpen(userPhotos, photo, photoAmount, mPhotoID, photoID) {
    this.photoID = photoID;
    this.photoViewerOpen = true;
    this.photoGalleryPressed = true;
    const self = this;

      $('.user-gallery').ready(function() {
        const userGallery = $('.user-gallery-photowrapper');

        $('body').css('overflow-y', 'hidden');
        $('html').css('overflow-y', 'hidden');

        userGallery.on('init', function(event) {
          const curslide = mPhotoID + 1;

          self.photoIDcount = mPhotoID;

          self.photoFace = userPhotos[mPhotoID].face;
            if (self.userPhotos[mPhotoID].face === true && self.userPhotos[mPhotoID].avatar !== true) {
                self.photoFace = true;
            } else {
                self.photoFace = false;
            }
          $('.ug-counter span.curphoto').html(curslide);
          $('.ug-counter span.allphoto').html(photoAmount);

        });

        userGallery.slick({
          infinite: true,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1
        });


        userGallery.slick('slickGoTo', mPhotoID, true);

        userGallery.on('afterChange', function(event, currentSlide) {
          self.UGMenuShow = false;

          getPhotoId();

          function  getPhotoId() {
            self.photoIDcount = currentSlide.currentSlide;
          }

          const counterr = currentSlide.currentSlide + 1;
          //self.photoCounter = counterr.toString(10);

          if (self.userPhotos[currentSlide.currentSlide].face === true &&
              self.userPhotos[currentSlide.currentSlide].avatar !== true) {
            self.photoFace = true;
          } else {
              self.photoFace = false;
          }

          $('.ug-counter span.curphoto').html(counterr);
          $('.ug-counter span.allphoto').html(photoAmount);
        });

      });
  }
  galleryClose() {
    this.photoViewerOpen = false;
    this.photoGalleryPressed = false;
    this.UGMenuShow = false;
    $('.photo-viewer').ready(function () {
      $('.user-gallery').slick('unslick');
      $('body').css('overflow-y', '');
      $('html').css('overflow-y', '');
    });
  }

  galleryMenuToggle() {
    if (this.UGMenuShow === true) {
      this.UGMenuShow = false;
    } else {
      this.UGMenuShow = true;
    }
  }

  setMainPhoto() {
    const id = this.photoIDcount;
    if (this.photoIDcount) {
      this.photoID = this.userPhotos[id].id;
      this.spinner = true;
    }
    this.photoService.photoSetMain(this.userSession, this.photoID).subscribe( res => {
      if (res['meta'].success === true) {
        // Скрываем спиннер
        this.spinner = false;
        // Обновляем аватар
        this.userAvatar = this.userPhotos[id].preview_url;
        this.userAvatarLarge = this.userPhotos[id].url;
        this.galleryClose();
      }
      this.ngOnInit();
    });
  }
  addPhoto(element) {
    const file = element.target.files[0];
    const formData: FormData = new FormData();

    formData.append('file', file, 'userimage.jpg');

    this.spinner = true;

    this.photoService.photoLoad(this.userSession, formData).subscribe(res => {
      if (res['meta'].success === true) {
        // Скрываем спиннер
        this.spinner = false;
        // Обновляем фотгографии и аватар
        this.userPhotos =  res['meta'].user.profile.photos;
        this.avatarID = res['meta'].user.profile.avatar.id;
        this.userAvatar = res['meta'].user.profile.avatar.preview_url;
        this.userAvatarLarge = res['meta'].user.profile.avatar.url;

        if (res['meta'].user.profile.avatar && res['meta'].user.profile.avatar.face) {
          this.userAvatarExist = true;
        } else {
          this.userAvatarExist = false;
          this.userAvatar = './assets/img/icons/sampleman.jpg';
        }

        if (res['meta'].avatarFace === false) {
            this.appComponent.isPopupPhotoWarning = 'show';
        }
      }
    });

    if (this.photoViewerOpen === true) {
      this.galleryClose();
    }
  }
  removePhoto() {
    if (this.photoIDcount) {
      const id = this.photoIDcount;
      this.photoID = this.userPhotos[id].id;
    }
    this.photoService.photoRevome(this.userSession, this.photoID).subscribe( res => {
      if (res['meta'].success === true) {
        // Обновляем фотографии
        this.userPhotos =  res['meta'].user.profile.photos;
        // Обновляем параметры аватара
        if (res['meta'].user.profile.avatar && res['meta'].user.profile.avatar.face) {
          this.userAvatarExist = true;
          this.avatarID = res['meta'].user.profile.avatar.id;
          this.userAvatar = res['meta'].user.profile.avatar.preview_url;
          this.userAvatarLarge = res['meta'].user.profile.avatar.url;
        } else {
          this.userAvatarExist = false;
          this.userAvatar = './assets/img/icons/sampleman.jpg';
        }
        this.galleryClose();
      }
    });
  }
  removeAvatar() {
    this.photoService.photoRevome(this.userSession, this.avatarID).subscribe( res => {
      if (res['meta'].success === true) {
        // Обновляем фотографии
        this.userPhotos =  res['meta'].user.profile.photos;
        // Обновляем параметры аватара
        if (res['meta'].user.profile.avatar && res['meta'].user.profile.avatar.face) {
          this.userAvatarExist = true;
          this.avatarID = res['meta'].user.profile.avatar.id;
          this.userAvatar = res['meta'].user.profile.avatar.preview_url;
          this.userAvatarLarge = res['meta'].user.profile.avatar.url;
        } else {
          this.userAvatarExist = false;
          this.userAvatar = './assets/img/icons/sampleman.jpg';
        }
        this.galleryClose();
      }
    });
  }

  ngOnDestroy() {
      this.photoViewerOpen = false;
      this.photoGalleryPressed = false;
      this.UGMenuShow = false;
      $('.photo-viewer').ready(function () {
          $('.user-gallery').slick('unslick');
          $('body').css('overflow-y', '');
          $('html').css('overflow-y', '');
      });
    this.subscription.unsubscribe();
  }

}
