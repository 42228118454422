import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { UserdataService } from '../../service/userdata.service';
import { UsereditService } from '../../service/useredit.service';


function  HeightOutOfRange(control: FormControl) {
  const val = control.value;
  console.log(val);
  if (val === 0) {
    return null;
  } else if (val < 100 || val > 220) {
    return {
      HeightOutOfRange
    };
  }
  return null;
}

function  WeightOutOfRange(control: FormControl) {
  const val = control.value;
  console.log(val);
  // if (val === null || val === '' || val === 0) {}
  if (val === 0) {
    return null;
  } else if (val < 40 || val > 200) {
    return {
      WeightOutOfRange
    };
  }
  return null;
}

@Component({
  selector: 'app-profile-edit-interes',
  templateUrl: './profile-edit-ineteres.component.html',
  styleUrls: ['./profile-edit-ineteres.component.less']
})
export class ProfileEditInteresComponent implements OnInit {
  spinner = true;
  // user session
  userSession = localStorage.getItem('userSession');
  // user data
  userData = JSON.parse(localStorage.getItem('user'));

  // Форм контрол языков
  userLanguages = new FormControl('');

  // Форм контрол роста пользователя
  userHeight = new FormControl('', [HeightOutOfRange]);

  // Форм контрол веса пользователя
  userWeight = new FormControl('', [WeightOutOfRange]);

  // Объект хобби пользователя
  lHobbies: any[];
  // Форм контрол хобби пользователя
  userHobbies = new FormControl('');

  editError: any[];
  editErrorH = false;
  editErrorW = false;
    private browserLang: any;
    private activeHobbies = {};


  getErrorMessageUserHeight() {
      if (this.browserLang === 'kz') {
          return this.userHeight.hasError('HeightOutOfRange') ? '100-220см аралыңында сан енгізіңіз' :
              '';
      } else {
          return this.userHeight.hasError('HeightOutOfRange') ? 'Введите число в диапазоне 100 - 220см' :
              '';
      }
  }
  getErrorMessageUserWeight() {
      if (this.browserLang === 'kz') {
          return this.userWeight.hasError('WeightOutOfRange') ? '40-200кг аралыңында сан енгізіңіз' :
              '';
      } else {
          return this.userWeight.hasError('WeightOutOfRange') ? 'Введите число в диапазоне 40 - 200кг' :
              '';
      }
  }

  constructor(private userdataService: UserdataService,
              private translate: TranslateService, private usereditService: UsereditService, private router: Router, public appComponent: AppComponent) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
      this.spinner = true;

      window.scrollTo(0, 0);
      this.activeHobbies = {};
      this.appComponent.currentUrl = 'profile-edit-interes';

      this.usereditService.optionsRequest(this.userSession).subscribe(optRes => {
        console.log(optRes);
        // Обновляем сессию в Local Storage
        this.userSession = optRes['meta'].user.session;
        localStorage.setItem('userSession', this.userSession);

        // Обновляем пользователя в Local Storage
        this.userData = optRes['meta'].user;
        localStorage.setItem('user', JSON.stringify(optRes['meta'].user));
        this.lHobbies = optRes['data'][13].hobbies;
        //this.activeHobbies.length = this.lHobbies.length;
      });

      this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {

        // Обновляем сессию в Local Storage
        this.userSession = udRes['meta'].user.session;
        localStorage.setItem('userSession', this.userSession);
        // Обновляем пользователя в Local Storage
        this.userData = udRes['meta'].user;
        localStorage.setItem('user', JSON.stringify(udRes['meta'].user));
        if (this.userData.profile.hobbies) {
          this.userHobbies.setValue(this.userData.profile.hobbies.map(function (item) { return item.id; }));
        }

          // user hobbies
          if (!this.userData.profile.hobbies || this.userData.profile.hobbies.length === 0) {
          } else {
              this.userHobbies = this.userData.profile.hobbies;
          }
          setTimeout(() => {
              this.userData.profile.hobbies.forEach((item, id) => {
                  this.activeHobbies[item.id] = true;
              });
          }, 1000);
          // Скрываем спиннер
      });


    this.editError = [this.editErrorH, this.editErrorW];

    this.spinner = false;
  }

  sendNewProfileData1($event) {
      this.spinner = true;
    if ($event === true) {

      if (this.userLanguages.value === '') {
        this.userLanguages.setValue([]);
      }
        //this.activeHobbies = this.activeHobbies.filter(Number);
      const userHobbies = Object.keys(this.activeHobbies);
      this.usereditService.sendNewData(this.userSession, {
        data: {
          profile: {
            hobbies: userHobbies
          }
        }
      }).subscribe(res => {
        // Обновляем сессию в Local Storage
        this.userSession = res['meta'].user.session;
        localStorage.setItem('userSession', this.userSession);
        // Обновляем пользователя в Local Storage
        this.userData = res['meta'].user;
        localStorage.setItem('user', JSON.stringify(res['meta'].user));

        if (res['meta'].success === true) {
          this.router.navigate(['profile-edit']);
        }
      });
    }
  }

    addHobbie(currentId) {
        console.log(currentId);
        if (this.activeHobbies[currentId]) {
            delete this.activeHobbies[currentId];
        } else {
            this.activeHobbies[currentId] = true;
        }
    }
}
