import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { UserdataService } from '../../service/userdata.service';
import { UsereditService } from '../../service/useredit.service';


function  HeightOutOfRange(control: FormControl) {
  const val = control.value;
  console.log(val);
  if (val === 0) {
    return null;
  } else if (val < 100 || val > 220) {
    return {
      HeightOutOfRange
    };
  }
  return null;
}

function  WeightOutOfRange(control: FormControl) {
  const val = control.value;
  console.log(val);
  // if (val === null || val === '' || val === 0) {}
  if (val === 0) {
    return null;
  } else if (val < 40 || val > 200) {
    return {
      WeightOutOfRange
    };
  }
  return null;
}

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.less']
})
export class ProfileEditComponent implements OnInit {
  spinner = true;
  // user session
  userSession = localStorage.getItem('userSession');
  // user data
  userData = JSON.parse(localStorage.getItem('user'));

  // Обьект целей знакомств
  lPurposes: any[];
  // Форм контрол цели знакомства
  userPurpose = new FormControl('');

  // Объект отношений
  lMaritals: any[];
  // Форм контрол отношения
  userMarital = new FormControl('');

  // Объект детей
  lChildrens: any[];
  // Форм контрол детей
  userChildrens = new FormControl('');

  // Объект курения
  lSmoking: any[];
  // Форм контрол курения
  userSmoking = new FormControl('');

  // Объект алкоголя
  lAlcohol: any[];
  // Форм контрол алкоголя
  userAlcohol = new FormControl('');

  // Объект образования
  lEducation: any[];
  // Форм контрол образования
  userEducation = new FormControl('');

  // Объект языков
  lLanguages: any[];
  // Форм контрол языков
  userLanguages = new FormControl('');

  // Форм контрол роста пользователя
  userHeight = new FormControl('', [HeightOutOfRange]);

  // Форм контрол веса пользователя
  userWeight = new FormControl('', [WeightOutOfRange]);

  // Объект тела
  lBodyes: any[];
  // Форм контрол тела пользователя
  userBody = new FormControl('');

  // Объект глаз
  lEyes: any[];
  // Форм контрол глаз пользователя
  userEyes = new FormControl('');

  // Объект волос
  lHairs: any[];
  // Форм контрол волос пользователя
  userHair = new FormControl('');

  // Форм контрол био пользователя
  userAbout = new FormControl('');

  // Объект хобби пользователя
  lHobbies: any[];
  // Форм контрол хобби пользователя
  userHobbies = new FormControl('');

  editError: any[];
  editErrorH = false;
  editErrorW = false;
    private browserLang: any;
    public placeholderAbout: any;
    public placeholderHobbi: any;


  getErrorMessageUserHeight() {
      if (this.browserLang === 'kz') {
          return this.userHeight.hasError('HeightOutOfRange') ? '100-220см аралыңында сан енгізіңіз' :
              '';
      } else {
          return this.userHeight.hasError('HeightOutOfRange') ? 'Введите число в диапазоне 100 - 220см' :
              '';
      }
  }
  getErrorMessageUserWeight() {
      if (this.browserLang === 'kz') {
          return this.userWeight.hasError('WeightOutOfRange') ? '40-200кг аралыңында сан енгізіңіз' :
              '';
      } else {
          return this.userWeight.hasError('WeightOutOfRange') ? 'Введите число в диапазоне 40 - 200кг' :
              '';
      }
  }

  constructor(private userdataService: UserdataService,
              private translate: TranslateService, private usereditService: UsereditService, private router: Router, public appComponent: AppComponent) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
      this.appComponent.currentUrl = 'profile-edit';

      this.usereditService.optionsRequest(this.userSession).subscribe(optRes => {
        console.log(optRes);

        // Скрываем спиннер
        this.spinner = false;

        // Обновляем сессию в Local Storage
        this.userSession = optRes['meta'].user.session;
        localStorage.setItem('userSession', this.userSession);

        // Обновляем пользователя в Local Storage
        this.userData = optRes['meta'].user;
        localStorage.setItem('user', JSON.stringify(optRes['meta'].user));

        this.lPurposes = optRes['data'][12].purpose;
        this.lMaritals = optRes['data'][11].marital;
        this.lChildrens = optRes['data'][4].children;
        this.lSmoking = optRes['data'][5].smoking;
        this.lAlcohol = optRes['data'][6].alcohol;
        this.lEducation = optRes['data'][3].education;
        this.lLanguages = optRes['data'][10].languages;
        this.lBodyes = optRes['data'][9].body;
        this.lEyes = optRes['data'][7].eyes;
        this.lHairs = optRes['data'][8].hair;
        this.lHobbies = optRes['data'][13].hobbies;
      });

      this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {

          // Обновляем сессию в Local Storage
        this.userSession = udRes['meta'].user.session;
        localStorage.setItem('userSession', this.userSession);
        // Обновляем пользователя в Local Storage
        this.userData = udRes['meta'].user;
        localStorage.setItem('user', JSON.stringify(udRes['meta'].user));

        console.log(this.userData.profile.smoking.id);
        console.log(this.userData.profile.height);
        console.log(this.userHeight);

        this.userPurpose.setValue(this.userData.profile.purpose.id);
        this.userMarital.setValue(this.userData.profile.marital.id);
        this.userChildrens.setValue(this.userData.profile.children.id);
        this.userSmoking.setValue(this.userData.profile.smoking.id);
        this.userAlcohol.setValue(this.userData.profile.alcohol.id);
        this.userEducation.setValue(this.userData.profile.education.id);
        if (this.userData.profile.languages) {
          this.userLanguages.setValue(this.userData.profile.languages.map(function (item) { return item.id; }));
        }
        this.userHeight.setValue(this.userData.profile.height);
        this.userWeight.setValue(this.userData.profile.weight);
        this.userBody.setValue(this.userData.profile.body.id);
        this.userEyes.setValue(this.userData.profile.eyes.id);
        this.userHair.setValue(this.userData.profile.hair.id);
        this.userAbout.setValue(this.userData.profile.bio);
        this.placeholderHobbi = true;
        if (this.userData.profile.hobbies.length > 0) {
          this.userHobbies.setValue(this.userData.profile.hobbies.map(function (item) { return item.id; }));
          this.placeholderHobbi = false;
        }

      });


    this.editError = [this.editErrorH, this.editErrorW];

    this.userHeight.valueChanges.subscribe(value => {
      if (this.userHeight.errors) {
        this.editErrorH = true;
      } else {
        this.editErrorH = false;
      }
      this.editError = [this.editErrorH, this.editErrorW];
    });

    this.userWeight.valueChanges.subscribe(value => {
      if (this.userWeight.errors) {
        this.editErrorW = true;
      } else {
        this.editErrorW = false;
      }
      this.editError = [this.editErrorH, this.editErrorW];
    });



  }

  sendNewProfileData($event) {
    if ($event === true) {

      if (this.userLanguages.value === '') {
        this.userLanguages.setValue([]);
      }
      if (this.userHobbies.value === '') {
        this.userHobbies.setValue([]);
      }

      this.usereditService.sendNewData(this.userSession, {
        data: {
          profile: {
            purpose: this.userPurpose.value,
            marital: this.userMarital.value,
            children: this.userChildrens.value,
            smoking: this.userSmoking.value,
            alcohol: this.userAlcohol.value,
            education: this.userEducation.value,
            languages: this.userLanguages.value,
            height: this.userHeight.value,
            weight: this.userWeight.value,
            body: this.userBody.value,
            eyes: this.userEyes.value,
            hair: this.userHair.value,
            bio: this.userAbout.value,
            hobbies: this.userHobbies.value
          }
        }
      }).subscribe(res => {
        console.log(res);
        // Обновляем сессию в Local Storage
        this.userSession = res['meta'].user.session;
        localStorage.setItem('userSession', this.userSession);
        // Обновляем пользователя в Local Storage
        this.userData = res['meta'].user;
        localStorage.setItem('user', JSON.stringify(res['meta'].user));

        if (res['meta'].success === true) {
          this.router.navigate(['']);
        }
      });
    }
  }

}
