import { Injectable } from '@angular/core';
import { SocketService } from './websocket.service';
import { Subject } from 'rxjs/Subject';

// const session = localStorage.getItem('userSession');

export interface Message {
  event: any;
  data: any;
}

@Injectable()
export class ChatService {
  public session;

  public messages: Subject<Message>;

  constructor(private wsService: SocketService) {
    this.messages = new Subject<Message>();
  }

  public message$(session) {
    this.messages = <Subject<Message>>this.wsService
      .creat(session)
      .map((response: MessageEvent): Message => {
        const data = JSON.parse(response.data);
        console.warn(response.data);
        // console.log('CHAT', response);
        return data;
    });
  }


  public send(message: Message): void {
      this.messages.next(message);
  }

    public disconnect() {
        this.wsService.disconnect();
    }
}
