import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { OptionsData } from '../models/options.model';
import { requestEditedData } from '../models/useredit.model';
import { requestFilterData } from '../models/filter.model';
import {requestEditedSettingsData} from '../models/settings.model';

@Injectable()

export class UsereditService {

  constructor(private http: HttpClient) { }

  optionsRequest(session) {
    return this.http.get<OptionsData>('/api/v1/user/options', { params: {
      session: session
    }});
  }

  // sendEditedData(session, userPurpose, userMarital, userChildrens, userSmoking, userAlcohol, userEducation, userLanguages, userHeight, userWeight, userBody, userEyes, userHair, userAbout, userHobbies) {
  //   return this.http.post('/api/v1/user/edit', requestEditedData(
  //     userPurpose,
  //     userMarital,
  //     userChildrens,
  //     userSmoking,
  //     userAlcohol,
  //     userEducation,
  //     userLanguages,
  //     userHeight,
  //     userWeight,
  //     userBody,
  //     userEyes,
  //     userHair,
  //     userAbout,
  //     userHobbies
  //   ), { params: {
  //     session: session
  //   }});
  // }

  sendNewData(session, userDataNew) {
    return this.http.post('/api/v1/user/edit', userDataNew, { params: {
        session: session
      }});
  }

  sendFilter(session, filterGender, filterAgeFrom, filterAgeTo, filterNearby) {
    return this.http.post('/api/v1/user/edit',
      requestFilterData(filterGender, filterAgeFrom, filterAgeTo, filterNearby),
      { params: { session: session } });
  }

  sendSettingsData(session, userName, userNickName, userGender, userBdate, userCity, userUIlang, userlSms) {
    return this.http.post('/api/v1/user/edit', requestEditedSettingsData(
      userName,
      userNickName,
      userGender,
      userBdate,
      userCity,
      userUIlang,
      userlSms
    ), { params: {
        session: session
    }});
  }

}
