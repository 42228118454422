import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.less']
})
export class CompetitionComponent implements OnInit {

  showAbout = true;
  userSession = localStorage.getItem('userSession');
  public browserLang: any;
  public image: String;

  constructor( private router: Router, private translate: TranslateService) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {

  }

  toHome(){
      window.scrollTo(0, 0);
      this.router.navigate(['/']);
  }

}