import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()

export class UserdataService {

  constructor(private http: HttpClient) { }

  userdataRequest(session) {
    return this.http.get('/api/v1/user', { params: {
      session: session
    }});
  }

}
