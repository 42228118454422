export class GameData {
  data: {
    skip: any;
  };
}

export function requestGameData(arg1): GameData {
  return {
    data: {
      skip: {
        arg1
      }
    }
  };
}
