import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  public currDate: Date;

  constructor(private router: Router, public appComponent: AppComponent)
  {
    this.currDate = new Date();
  }

  ngOnInit() {

  }
}
