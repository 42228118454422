import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { FeedBackService } from '../../service/feedback.service';
import { UserdataService } from '../../service/userdata.service';

@Component({
  selector: 'app-profile-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.less']
})
export class FeedbackComponent implements OnInit {
  spinner = true;
  // user session
  userSession = localStorage.getItem('userSession');
  // user data
  userData = JSON.parse(localStorage.getItem('user'));


    feedback = new FormControl('');
    private browserLang: any;
    private activeHobbies = [];

  constructor(private userdataService: UserdataService,
              private translate: TranslateService, private usereditService: FeedBackService, private router: Router, public appComponent: AppComponent) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
      window.scrollTo(0, 0);
      this.activeHobbies = [];
      this.appComponent.currentUrl = 'feedback';

      // Скрываем спиннер
      this.spinner = false;
  }

  sendNewProfileData($event) {
    console.log($event);
    if ($event === true) {
        this.activeHobbies = this.activeHobbies.filter(Number);
      this.usereditService.sendNewData(this.userSession, {
        data: {
            message: this.feedback.value
        }
      }).subscribe(res => {
        // Обновляем сессию в Local Storage
        this.userSession = res['meta'].user.session;
        localStorage.setItem('userSession', this.userSession);
        // Обновляем пользователя в Local Storage
        this.userData = res['meta'].user;
        localStorage.setItem('user', JSON.stringify(res['meta'].user));

        if (res['meta'].success === true) {
          this.router.navigate(['/settings']);
        }
      });
    }
  }

    addHobbie(currentId) {
        console.log(currentId);
        if (this.activeHobbies[currentId - 1] === currentId) {
            this.activeHobbies[currentId - 1] = false;
        } else {
            this.activeHobbies[currentId - 1] = currentId;
        }
    }
}
