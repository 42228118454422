import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import 'slick-carousel';
import { AppComponent } from '../../app.component';
import { BanService } from '../../service/ban.service';
import { FriendService } from '../../service/friend.service';
import { GameService } from '../../service/game.service';
import { PhotoService } from '../../service/photo.service';
import { UserdataService } from '../../service/userdata.service';

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.less']
})
export class ProfileUserComponent implements OnInit {
  spinner = true;
  spinnerAva = true;
  spinnerPic = true;

  // user session
  userSession = localStorage.getItem('userSession');
  // user data
  userData = JSON.parse(localStorage.getItem('user'));
  // friend ID
  friendID = localStorage.getItem('friendID');

  friendData: any;

  // friend specials
  friendOnline: boolean;
  friendDoubleLike: boolean;
  friendLike: boolean;
  friendInBan: boolean;
  friendBan: boolean;

  // friend name
  friendName = '';
  // friend city
  friendCity = '';
  // friend age
  friendAge = '';
  // friend zodiac
  friendZodiac = '';
  // friend avatar
  friendAvatar = '';
  // friend avatar large
  friendAvatarLarge = '';
  // friend photos
  friendPhotos: any;

  // friend purpose
  friendPurpose = '';
  // friend marital
  friendMarital = '';
  // friend children
  friendChildren = '';
  // friend languages
  friendLanguages = '';
  // friend smoking
  friendSmoking = '';
  // friend alcohol
  friendAlcohol = '';
  // friend education
  friendEducation = '';
  // friend height
  friendHeight = '';
  // friend weight
  friendWeight = '';
  // friend body
  friendBody = '';
  // friend eyes
  friendEyes = '';
  // friend hair
  friendHair = '';
  // friend bio
  friendBio = '';
  // friend hobbies
  friendHobbies = '';

   public reportMess = new FormControl('');

  avatarPressed = false;
  photoViewerOpen = false;
  photoGalleryPressed = false;
  friendLanguagesPlaceholder = false;
  friendBioPlaceholder = false;
  friendHobbiesPlaceholder = false;
  friendAvatarExist = false;

  UGMenuShow = false;
  photoIDcount = '';
  photoID = '';
  avatarID = '';

  openModal = false;
  modalContent = '';

  // Нахождение склонения существительного после возраста пользователя
  checkAge(friendAge, agePostfix) {
    const cases = [2, 0, 1, 1, 1, 2];
    return friendAge + ' ' + agePostfix[ (friendAge % 100 > 4 && friendAge % 100 < 20) ? 2 : cases[(friendAge % 10 < 5) ? friendAge % 10 : 5] ];
  }

  avatarSpinner() {
    this.spinnerAva = false;
  }

  pictureSpinner() {
    this.spinnerPic = false;
  }

  constructor (
    private userdataService: UserdataService,
    private frienddataService: FriendService,
    private photoService: PhotoService,
    private gameService: GameService,
    private router: Router,
    public appComponent: AppComponent,
    private ban: BanService
  ) { }

  ngOnInit() {
      this.appComponent.currentUrl = 'profile-user';
      this.appComponent.isPopupReciprocity = 'hide';

      this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {
        if (udRes['errors']) {
          console.log('unauthorized!');
        } else if (udRes['meta'].user) {
          // Обновляем сессию в Local Storage
          console.log(udRes);
          this.userSession = udRes['meta'].user.session;
          localStorage.setItem('userSession', this.userSession);

          // user data
          this.userData = udRes['meta'].user;

          // Обновляем пользователя в Local Storage
          localStorage.setItem('user', JSON.stringify(udRes['meta'].user));

          this.frienddataService.frienddataRequest(this.userSession, this.friendID).subscribe(frRes => {
            // Скрываем спиннер
            this.spinner = false;
            this.avatarSpinner();

            console.log(frRes);

            this.friendData = frRes['data'][0];

            // friend specials
            this.friendOnline = this.friendData.online;
            this.friendLike = this.friendData.like;

            // this.friendReciprocity = this.friendData.reciprocity;
            this.friendDoubleLike = this.friendData.reciprocity;
            this.friendInBan = this.friendData.in_ban;
            if (this.friendInBan === true) {
              this.friendDoubleLike = false;
            }
            this.friendBan = this.friendData.ban;



            // user name
            this.friendName = this.friendData.profile.name;
            // friend city
            this.friendCity = this.friendData.profile.city.title;
            // friend age
            this.friendAge = this.friendData.profile.age;
            if (this.appComponent.flagLanguage === 'kz') {
                this.friendAge = this.checkAge(this.friendAge, ['Жас', 'Жас', 'Жас']);
            } else {
                this.friendAge = this.checkAge(this.friendAge, ['год', 'года', 'лет']);
            }
            // friend zodiac
            this.friendZodiac = this.friendData.profile.zodiac.title;
            // friend avatar
            if (this.friendData.profile.avatar) {
              this.friendAvatarExist = true;
              this.friendAvatar = this.friendData.profile.avatar.preview_url + '&w=260';
              this.friendAvatarLarge = this.friendData.profile.avatar.url;
            } else {
              this.friendAvatarExist = false;
              this.friendAvatar = './assets/img/icons/sampleman.jpg';
            }
            // friend photos
            this.friendPhotos = this.friendData.profile.photos;

            console.log(this.friendPhotos);
            console.log(this.friendPhotos.length);

            for (let i = 0; i < this.friendPhotos.length; i++) {
              this.friendPhotos[i].accsessRight = true;
              if (this.userData.permissions === 1) {
                this.friendPhotos[i].accsessRight = false;
                this.friendPhotos[0].accsessRight = true;
              }
            }

            // friend purpose
            this.friendPurpose = this.friendData.profile.purpose.title;
            // friend marital
            this.friendMarital = this.friendData.profile.marital.title;
            // friend children
            this.friendChildren = this.friendData.profile.children.title;
            // friend languages
            if (!this.friendData.profile.languages || this.friendData.profile.languages.length === 0) {
              this.friendLanguagesPlaceholder = true;
            } else {
              this.friendLanguages = this.friendData.profile.languages;
            }
            // friend smoking
            this.friendSmoking = this.friendData.profile.smoking.title;
            // friend alcohol
            this.friendAlcohol = this.friendData.profile.alcohol.title;
            // friend education
            this.friendEducation = this.friendData.profile.education.title;
            // friend height
            this.friendHeight = this.friendData.profile.height;
            // friend weight
            this.friendWeight = this.friendData.profile.weight;
            // friend body
            this.friendBody = this.friendData.profile.body.title;
            // friend eyes
            this.friendEyes = this.friendData.profile.eyes.title;
            // friend hair
            this.friendHair = this.friendData.profile.hair.title;
            // friend bio
            if (this.friendData.profile.bio) {
              this.friendBio = this.friendData.profile.bio;
            } else {
              this.friendBioPlaceholder = true;
            }
            // friend hobbies
            if (!this.friendData.profile.hobbies || this.friendData.profile.hobbies.length === 0) {
              this.friendHobbiesPlaceholder = true;
            } else {
              this.friendHobbies = this.friendData.profile.hobbies;
            }
          });

        }
      });
  }

  sendReport() {
      this.ban.sendReport(this.friendID, this.userSession, this.reportMess.value).subscribe( resRep => {
          if (resRep['meta'].success === true) {
              console.log(resRep['meta']);
              this.appComponent.isPopupReport = 'hide';
              this.appComponent.isPopupReportSuccess = 'show';
          }
      });
  }

  avatarOpen() {
    if (this.friendAvatarExist === true) {
      this.photoViewerOpen = true;
      this.avatarPressed = true;
      $('body').css('overflow-y', 'hidden');
      $('html').css('overflow-y', 'hidden');
    }
  }
  avatarClose() {
    this.photoViewerOpen = false;
    this.avatarPressed = false;
    this.UGMenuShow = false;
    $('body').css('overflow-y', '');
    $('html').css('overflow-y', '');
  }

  galleryOpen(friendPhotos, photo, photoAmount, mPhotoID, photoID) {
    this.photoID = photoID;
    this.photoViewerOpen = true;
    this.photoGalleryPressed = true;
    const self = this;
    // console.log(friendPhotos);
    // console.log(photo);
    // console.log(photoID);
    $('.user-gallery').ready(function() {
      const friendGallery = $('.user-gallery-photowrapper');

      $('body').css('overflow-y', 'hidden');
      $('html').css('overflow-y', 'hidden');

      friendGallery.on('init', function(event) {
        const curslide = mPhotoID + 1;
        $('.ug-counter span.curphoto').html(curslide);
        $('.ug-counter span.allphoto').html(photoAmount);
      });

      friendGallery.slick({
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1
      });

      friendGallery.slick('slickGoTo', mPhotoID, true);

      friendGallery.on('afterChange', function(event, currentSlide) {
        self.UGMenuShow = false;

        getPhotoId();

        function  getPhotoId() {
          self.photoIDcount = currentSlide;
          // console.log(self.photoIDcount);
        }

        const counterr = currentSlide.currentSlide + 1;
        //self.photoCounter = counterr.toString(10);
        // console.log('fires afterChange');
        // console.log(currentSlide + 1);
        // console.log(this.photoCounter);
        // console.log(photoAmount);
        //console.log(counterr);
        console.log(currentSlide.currentSlide);
        $('.ug-counter span.curphoto').html(counterr);
        $('.ug-counter span.allphoto').html(photoAmount);
      });

    });
  }

  galleryClose() {
    this.photoViewerOpen = false;
    this.photoGalleryPressed = false;
    this.UGMenuShow = false;
    $('.photo-viewer').ready(function () {
      $('.user-gallery').slick('unslick');
      $('body').css('overflow-y', '');
      $('html').css('overflow-y', '');
    });
  }

  setAttitude() {
    if (this.friendLike === false) {
      this.friendLike = true;
      this.gameService.sendLike(this.userSession, this.friendID).subscribe(res => {
        console.warn(res['meta']);
          if (res['meta'].reciprocity) {
              this.appComponent.isPopupReciprocity = 'show';
              this.friendDoubleLike = true;
          }
      });
    } else if (this.friendDoubleLike === true) {
      this.appComponent.isPopupReciprocity = 'hide';
      localStorage.setItem('friendID', this.friendID);
      localStorage.setItem('dialogID', this.friendID);
      localStorage.setItem('dialogFriendID', this.friendID);
      localStorage.setItem('dialogFriendAvatar', this.friendAvatar);
      localStorage.setItem('dialogFriendName', this.friendName);
      this.router.navigate(['dialog']);
    }
  }

  routeDialog() {
      localStorage.setItem('friendID', this.friendID);
      localStorage.setItem('dialogID', this.friendID);
      localStorage.setItem('dialogFriendID', this.friendID);
      localStorage.setItem('dialogFriendAvatar', this.friendAvatar);
      localStorage.setItem('dialogFriendName', this.friendName);
      this.router.navigate(['dialog']);
  }

  showModal($event) {
    console.log($event);
    if ($event.report) {
      if ($event.report === true) {
        this.openModal = true;
        this.modalContent = 'friend report';
      }
    } else if ($event.banConfirm) {
      if ($event.banConfirm === true) {
        this.openModal = true;
        this.modalContent = 'friend ban confirm';
      }
    } else if ($event.unBanConfirm) {
      if ($event.unBanConfirm === true) {
        this.openModal = true;
        this.modalContent = 'friend unban confirm';
      }
    }
  }
  receiveModalEvents($event) {
    console.log($event);
    if ($event.close === true) {
      this.openModal = false;
    }
    if ($event.reportMess) {
      this.openModal = false;
      this.ban.sendReport(this.friendID, this.userSession, $event.reportMess).subscribe( resRep => {
        if (resRep['meta'].success === true) {
          console.log(resRep['meta']);
          this.openModal = true;
          this.modalContent = 'report successful sent';
        }
      });
    }
    if ($event.ban) {
      if ($event.ban === true) {
        this.openModal = false;
        this.ban.sendToBan(this.friendID, this.userSession).subscribe( resBan => {
          if (resBan['meta'].success === true) {
            this.openModal = true;
            this.modalContent = 'friend ban';
          }
        });
      }
    }
    if ($event.unban) {
      if ($event.unban === true) {
        this.openModal = false;
        this.ban.sendToUnBan(this.friendID, this.userSession).subscribe( resBan => {
          if (resBan['meta'].success === true) {
            this.openModal = true;
            this.modalContent = 'friend unban';
          }
        });
      }
    }
  }

}
