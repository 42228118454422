import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../../app.component';
import { NotificatorService } from '../../../service/notificator.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.less']
})
export class PeopleComponent implements OnInit {
  spinner = true;

  showYouLike = JSON.parse(localStorage.getItem('showYouLike'));
  showYouBan = JSON.parse(localStorage.getItem('showYouBan'));
  topbarTitle = localStorage.getItem('topbarTitle');

  peopleILike = [];
  peopleIBan = [];
  placeholder = false;

  userSession = localStorage.getItem('userSession');

  peopleData = '';
    private browserLang: any;

  constructor(
    private router: Router,
    private translate: TranslateService,
    public appComponent: AppComponent,
    private notificator: NotificatorService,
  ) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
      this.appComponent.currentUrl = 'people';
      if (this.showYouLike.showYouLike === true) {
      this.notificator.getMyLikes(this.userSession).subscribe( likes => {
        // Скрываем спиннер
        this.spinner = false;
        this.peopleILike = likes['data'];
        if (likes['data'].length === 0) {
          this.placeholder = true;
        }
      });
    }
    if (this.showYouBan.showYouBan === true) {
      this.notificator.getMyBan(this.userSession).subscribe( ban => {
        // Скрываем спиннер
        this.spinner = false;
        this.peopleIBan = ban['data'];
        this.peopleData = 'ban';
        if (ban['data'].length === 0) {
          this.placeholder = true;
        }
      });
    }
  }

  goToUserPage($event) {
    localStorage.setItem('friendID', $event);
    this.router.navigate(['profile-user']);
  }

}
