import { HttpParams } from '@angular/common/http';

export let object_to_url = (obj_to_serialize): string => {

  if (typeof obj_to_serialize === 'string') {
    return obj_to_serialize;
  }

  const params = new HttpParams();

  for (const key in obj_to_serialize) {
    if (obj_to_serialize.hasOwnProperty(key)) {
      params.append(key, obj_to_serialize[key]);
    }
  }

  return params.toString();
};
