import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { HttpApiService } from "../../service/http-api/http-api.service";

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.less']
})
export class InfoComponent implements OnInit {
  lang: any;
  name: string;
  textPage: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public appComponent: AppComponent,
    private api: HttpApiService,
    private translate: TranslateService) { }

  async ngOnInit() {
    this.appComponent.currentUrl = 'info';

    window.scrollTo(0, 0);

    this.route.paramMap.subscribe(params => {
      this.name = params.get('name');
    });

    //const getInfoAbout = await this.api.get<any>('app/settings/about_service/web/' + this.lang);
    //const getInfoOpherta = await this.api.get<any>('app/settings/oferta/web/' + this.lang);

    this.textPage = await this.api.get<any>('app/settings/' + this.name + '/web/' + this.translate.currentLang);

    if (!this.textPage.data || !this.textPage.data[0]) {
      this.router.navigate(['']);
    }

    this.textPage = this.textPage.data[0];
  }
}
