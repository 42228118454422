import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { UserdataService } from '../../service/userdata.service';
import { UsereditService } from '../../service/useredit.service';


function  HeightOutOfRange(control: FormControl) {
  const val = control.value;
  console.log(val);
  if (val === 0) {
    return null;
  } else if (val < 100 || val > 220) {
    return {
      HeightOutOfRange
    };
  }
  return null;
}

function  WeightOutOfRange(control: FormControl) {
  const val = control.value;
  console.log(val);
  // if (val === null || val === '' || val === 0) {}
  if (val === 0) {
    return null;
  } else if (val < 40 || val > 200) {
    return {
      WeightOutOfRange
    };
  }
  return null;
}

@Component({
  selector: 'app-profile-edit-aboutme',
  templateUrl: './profile-edit-aboutme.component.html',
  styleUrls: ['./profile-edit-aboutme.component.less']
})
export class ProfileEditAboutmeComponent implements OnInit {
  spinner = true;
  // user session
  userSession = localStorage.getItem('userSession');
  // user data
  userData = JSON.parse(localStorage.getItem('user'));


  // Форм контрол языков
  userLanguages = new FormControl('');

    // Форм контрол био пользователя
    userAbout = new FormControl('');

  editError: any[];
  editErrorH = false;
  editErrorW = false;
    private browserLang: any;
    private activeHobbies = [];

  constructor(private userdataService: UserdataService,
              private translate: TranslateService, private usereditService: UsereditService, private router: Router, public appComponent: AppComponent) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
      window.scrollTo(0, 0);
      // this.activeHobbies = [];
      this.appComponent.currentUrl = 'profile-edit-aboutme';

      this.usereditService.optionsRequest(this.userSession).subscribe(optRes => {
          console.log(optRes);

          // Скрываем спиннер
          this.spinner = false;

          // Обновляем сессию в Local Storage
          this.userSession = optRes['meta'].user.session;
          localStorage.setItem('userSession', this.userSession);

          // Обновляем пользователя в Local Storage
          this.userData = optRes['meta'].user;
          localStorage.setItem('user', JSON.stringify(optRes['meta'].user));
      });

      this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {

          // Обновляем сессию в Local Storage
          this.userSession = udRes['meta'].user.session;
          localStorage.setItem('userSession', this.userSession);
          // Обновляем пользователя в Local Storage
          this.userData = udRes['meta'].user;
          localStorage.setItem('user', JSON.stringify(udRes['meta'].user));

          if (this.userData.profile.languages) {
              this.userLanguages.setValue(this.userData.profile.languages.map(function (item) { return item.id; }));
          }

          this.userAbout.setValue(this.userData.profile.bio);
      });


    this.editError = [this.editErrorH, this.editErrorW];
  }

    sendNewProfileData2($event) {
        console.log($event);
        if ($event === true) {
            this.usereditService.sendNewData(this.userSession, {
                data: {
                    profile: {
                        bio: this.userAbout.value
                    }
                }
            }).subscribe(res => {
                console.log(res);
                // Обновляем сессию в Local Storage
                this.userSession = res['meta'].user.session;
                localStorage.setItem('userSession', this.userSession);
                // Обновляем пользователя в Local Storage
                this.userData = res['meta'].user;
                localStorage.setItem('user', JSON.stringify(res['meta'].user));

                if (res['meta'].success === true) {
                    this.router.navigate(['profile-edit']);
                }
            });
        }
    }

}
