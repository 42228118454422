export class RegistrationData {
  data: {
    profile: {
      name: any;
      gender: any;
      birthday: any;
      city: any;
      height: any;
      weight: any;
      children: any;
      education: any;
    };
  };
}

export function requestRegistrationData(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8): RegistrationData {
  return {
    data: {
      profile: {
        name: arg1,
        gender: arg2,
        birthday: arg3,
        city: arg4,
        height: arg5,
        weight: arg6,
        children: arg7,
        education: arg8
      }
    }
  };
}
