import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ISubscription } from 'rxjs/Subscription';
import { AppComponent } from '../../../../app.component';
import { ChatService } from '../../../../service/chat.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.less']
})
export class MessageComponent implements OnInit, OnChanges, OnDestroy {

  @Input() messageData;

  subscription: ISubscription;

  message$: object;
    private browserLang: any;


  constructor(
    private elRef: ElementRef,
    private translate: TranslateService,
    public appComponent: AppComponent,
    private socket: ChatService
  ) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

    public flagToScroll = true;


  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['messageData']) {
      this.message$ = this.messageData;


      // scroll to last mess
      const last = Object.keys(this.message$).length - 1;
      console.log(last);
      const messPull = this.elRef.nativeElement.getElementsByClassName('message-line') as HTMLCollection;
      if (last > 0 && this.flagToScroll) {
        setTimeout( () => {
          messPull[last].scrollIntoView();
          this.flagToScroll = false;
          }, 100
        );
      }
    }
  }



  ngOnInit() {
    this.subscription = this.socket.messages.subscribe(wtf => {
      console.log(wtf);
      // console.log(wtf.event);
      // console.log(wtf.data);
      if (wtf.event === 'err_session_required') {
        console.log('WEBSOCKET : нет сессии!');
      }
      if (wtf.event === 'err_unauthorized') {
        console.log('WEBSOCKET : соединение НЕ установлено!');
      }
      if (wtf.event === 'success_authorized') {
        console.log('WEBSOCKET : установлено соединение!');
      }
      if (wtf.event === 'ping') {
        this.socket.send({'event': 'pong', 'data': ''});
        console.log('WEBSOCKET : получен ПИНГ!');
      }
      if (wtf.event === 'message_sent') {
        console.log(this.messageData[this.messageData.length - 1]);
        console.log(wtf.data.local_message_id);
        console.log(wtf.data.message_id);

        // Adding message_id to last element
        this.messageData[this.messageData.length - 1].message_id = wtf.data.message_id;
        console.log(this.messageData);

        // this.message$[this.message$]
        console.log('WEBSOCKET : сообщение ' + wtf.data.message_id + ' отправлено!');
        console.log('WEBSOCKET : сообщение отправлено!', wtf.data);

        if (this.messageData) {
          for (let i = 0; i < this.messageData.length; i++) {
            if (this.messageData[i].status === '') {
              this.messageData[i].status = 0;
            }
          }
        }

      }
      if (wtf.event === 'message_delivered') {
        console.log('WEBSOCKET : сообщение доставлено!');
        if (this.messageData) {
          for (let i = 0; i < this.messageData.length; i++) {
            if (this.messageData[i].status === 0) {
              this.messageData[i].status = 1;
            }
          }
        }
      }
      if (wtf.event === 'message_read') {
        console.log('WEBSOCKET : сообщение прочитано!');
        console.log(wtf);
        if (this.messageData) {
          for (let i = 0; i < this.messageData.length; i++) {
            if (this.messageData[i].status === 1) {
              this.messageData[i].status = 2;
            }
          }
        }
      }
      if (wtf.event === 'new_message') {
        console.log('WEBSOCKET : получено СООБЩЕНИЕ!');
        console.log(wtf.data);

        console.log(wtf.data.message_id);
        this.socket.send({'event': 'message_delivered', 'data': {'message_id': wtf.data.message_id}});
        console.log('Сообщаениe ' + wtf.data.message_id + ' прочитано!');

        // console.log(wtf.data.text.search('\n'));

        this.messageData.push(wtf.data);
        this.message$ = this.messageData;

        // scroll to last mess
        const last = Object.keys(this.message$).length - 1;
        const messPull = this.elRef.nativeElement.getElementsByClassName('message-line') as HTMLCollection;
        if (last > 0) {
          setTimeout( () => {
            messPull[last].scrollIntoView();
            }, 100
          );
        }
      }
      if ( wtf.event === 'new_message_from_me' ) {
        console.log('WEBSOCKET : получено СООБЩЕНИЕ ОТ МЕНЯ!');
        console.log(wtf.data);

        const newMessData = wtf.data;
        newMessData.status = 0;

        this.messageData.push(wtf.data);
        this.message$ = this.messageData;


        console.log('++++++++++', this.message$);

        // if (this.messageData) {
        //   for (let i = 0; i < this.messageData.length; i++) {
        //     if (this.messageData[i].status === 0) {
        //       this.messageData[i].status = 1;
        //     }
        //   }
        // }

        // scroll to last mess
        const last = Object.keys(this.message$).length - 1;
        const messPull = this.elRef.nativeElement.getElementsByClassName('message-line') as HTMLCollection;
        if (last > 0) {
          setTimeout( () => {
              messPull[last].scrollIntoView();
            }, 100
          );
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
