import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularDraggableModule } from 'angular2-draggable';
import { TextMaskModule } from 'angular2-text-mask';
import { NouisliderModule } from 'ng2-nouislider';
import { DndModule } from 'ngx-drag-drop';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CompetitionComponent } from './components/competition/competition.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FilterComponent } from './components/filter/filter.component';
import { FooterComponent } from './components/footer/footer.component';
import { GameComponent } from './components/game/game.component';
import { InfoComponent } from './components/info/info.component';
import { DateconvertMessagesPipe, DateconvertPipe, DialogComponent, ReversePipe, TimeconvertPipe } from './components/messages/dialog/dialog.component';
import { MessageComponent } from './components/messages/dialog/message/message.component';
import { MessagesComponent } from './components/messages/messages.component';
import { NotificationsEditComponent } from './components/notifications/notifications-edit/notifications-edit.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ProfileEditAboutmeComponent } from './components/profile-edit-aboutme/profile-edit-aboutme.component';
import { ProfileEditInteresComponent } from './components/profile-edit-ineteres/profile-edit-ineteres.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { ProfileUserComponent } from './components/profile-user/profile-user.component';
import { ProfileComponent } from './components/profile/profile.component';
import { PeopleComponent } from './components/settings/people/people.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { AuthRedirector } from './service/auth.redirector';
import { AuthService } from './service/auth.service';
import { BanService } from './service/ban.service';
import { ChatService } from './service/chat.service';
import { ConversatonsService } from './service/conversatons.service';
import { FeedBackService } from './service/feedback.service';
import { FriendService } from './service/friend.service';
import { GameService } from './service/game.service';
import { HttpApiService } from './service/http-api/http-api.service';
import { LogoutService } from './service/logout.service';
import { NotificatorService } from './service/notificator.service';
import { PhotoService } from './service/photo.service';
import { RegistrationService } from './service/registration.service';
import { SubscribeService } from './service/subscribe.service';
import { UserdataService } from './service/userdata.service';
import { UsereditService } from './service/useredit.service';
import { SocketService } from './service/websocket.service';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, "./assets/i18n/");
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    ProfileUserComponent,
    ProfileEditInteresComponent,
    ProfileEditAboutmeComponent,
    FeedbackComponent,
    TopbarComponent,
    ProfileEditComponent,
    GameComponent,
    FilterComponent,
    MessagesComponent,
    DialogComponent,
    MessageComponent,
    ReversePipe,
    TimeconvertPipe,
    DateconvertPipe,
    DateconvertMessagesPipe,
    NotificationsComponent,
    NotificationsEditComponent,
    SettingsComponent,
    PeopleComponent,
    InfoComponent,
    FooterComponent,
    CompetitionComponent
  ],
  imports: [
    DndModule,
    AngularDraggableModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    HttpClientModule,
    AppRoutingModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    NouisliderModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }),
  ],
  providers: [
    AuthService,
    AuthRedirector,
    RegistrationService,
    UserdataService,
    LogoutService,
    UsereditService,
    FeedBackService,
    PhotoService,
    GameService,
    FriendService,
    ConversatonsService,
    SocketService,
    ChatService,
    NotificatorService,
    BanService,
    HttpApiService,
    SubscribeService,
    {
        provide: APP_BASE_HREF,
        useValue: '/app'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
