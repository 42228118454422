import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProfileComponent } from './components/profile/profile.component';
import { ProfileUserComponent } from './components/profile-user/profile-user.component';
import { ProfileEditComponent } from './components/profile-edit/profile-edit.component';
import { GameComponent } from './components/game/game.component';
import { FilterComponent } from './components/filter/filter.component';
import { MessagesComponent } from './components/messages/messages.component';
import { DialogComponent } from './components/messages/dialog/dialog.component';
import { AuthService } from './service/auth.service';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsEditComponent } from './components/notifications/notifications-edit/notifications-edit.component';
import { SettingsComponent } from './components/settings/settings.component';
import { PeopleComponent } from './components/settings/people/people.component';
import { InfoComponent } from './components/info/info.component';
import { ProfileEditInteresComponent } from './components/profile-edit-ineteres/profile-edit-ineteres.component';
import { ProfileEditAboutmeComponent } from './components/profile-edit-aboutme/profile-edit-aboutme.component';
import { FeedbackComponent } from './components/feedback/feedback.component';

const routes: Routes = [
  { path: 'info/:name', component: InfoComponent },
  { path: '',
  canActivate: [AuthService],
  children: [
      { path: '', component: ProfileComponent },
      { path: 'profile-user', component: ProfileUserComponent },
      { path: 'profile-edit', component: ProfileEditComponent },
      { path: 'profile-edit-interes', component: ProfileEditInteresComponent },
      { path: 'profile-edit-aboutme', component: ProfileEditAboutmeComponent },
      { path: 'feedback', component: FeedbackComponent },
      { path: 'game', component: GameComponent },
      { path: 'filter', component: FilterComponent },
      { path: 'messages', component: MessagesComponent },
      { path: 'dialog', component: DialogComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'notifications-edit', component: NotificationsEditComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'people', component: PeopleComponent }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
