import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { OptionsData } from '../models/options.model';
import { requestRegistrationData } from '../models/registration.model';

@Injectable()

export class RegistrationService {

  constructor(private http: HttpClient) { }

  optionsRequest(session) {
    return this.http.get<OptionsData>('/api/v1/user/options', { params: {
      session: session
    }});
  }

  sendRegistration(session, userName, userGender, userBirthdate, userCity, userHeight, userWeight, userChildren, userEducation) {
    return this.http.post('/api/v1/user/edit', requestRegistrationData(
      userName,
      userGender,
      userBirthdate,
      userCity,
      userHeight,
      userWeight,
      userChildren,
      userEducation
      ), { params: {
      session: session
    }});
  }

}
