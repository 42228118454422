import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()

export class LogoutService {

  constructor(private http: HttpClient) { }

  logout(session) {
    return this.http.get('/api/v1/user/logout', { params: {
      session: session
    }});
  }

  deleteMe(session) {
    return this.http.get('/api/v1/user/remove', {params: {
      session: session
      }});
  }

}
