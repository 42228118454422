import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import * as Rx from 'rxjs/Rx';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';


@Injectable()
export class SocketService {
  private socket;
  public newMes;
  public isConnected = false;
  public interval = null;

  private subject: Subject<MessageEvent>;
  sess: string;

  constructor() {}

    public creat(sess): Subject<MessageEvent> {
      this.sess = sess;

      if (!this.subject) {
        this.subject = this.connect(sess);
      }

      return this.subject;
    }

    connect(session): Rx.Subject<MessageEvent> {
        this.socket = io(environment.wsServer + '?session=' + session +  '');

        const observable = Observable.create((obs: Observer<MessageEvent>) => {
            this.socket.on('message', (data) => {
                if (JSON.parse(data).event === 'new_message') {
                    this.socket.send({
                      'event': 'message_read',
                      'data': {
                        'message_id': data.message_id
                      }
                    });
                    this.newMes = true;
                }

                observer.next(data);
            });

            this.socket.on('connect', () => {
                this.isConnected = true;

                this.socket.on('authenticated', function() {

                });
            });

            this.socket.on('disconnect', () => {
                this.isConnected = false;

                window.setTimeout(() => {
                    if (!this.isConnected) {
                        this.connect(session);
                    }
                }, 1000);
            });

            return () => {
                this.socket.disconnect();
            };
        }).pipe(share());

        const observer = {
            next: (data: Object) => {
                this.socket.emit('message', JSON.stringify(data));
            },
        };

        return Rx.Subject.create(observer, observable);
    }

    disconnect () {
        this.socket.disconnect();
    }
}

class ReconnectingWebSocket {
  // These can be altered by calling code
  public debug = false;

  // Time to wait before attempting reconnect (after close)
  public reconnectInterval = 5000;
  // Time to wait for WebSocket to open (before aborting and retrying)
  public timeoutInterval = 5000;

  // Should only be used to read WebSocket readyState
  public readyState: number;

  // Whether WebSocket was forced to close by this client
  private forcedClose = false;
  // Whether WebSocket opening timed out
  private timedOut  = false;

  // List of WebSocket sub-protocols
  private protocols: string[] = [];

  // The underlying WebSocket
  private ws: WebSocket;
  private url: string;

  /**
   * Setting this to true is the equivalent of setting all instances of ReconnectingWebSocket.debug to true.
   */

  public static debugAll = false;

  // Set up the default 'noop' event handlers
  public onopen: (ev: Event) => void = function (event: Event) {};
  public onclose: (ev: CloseEvent) => void = function (event: CloseEvent) {};
  public onconnecting: () => void = function () {};
  public onmessage: (ev: MessageEvent) => void = function (event: MessageEvent) {};
  public onerror: (ev: ErrorEvent) => void = function (event: ErrorEvent) {};

  constructor(url: string, protocols: string[] = []) {
    this.url = url;
    this.protocols = protocols;
    this.readyState = WebSocket.CONNECTING;
    this.connect(false);
  }

  public connect(reconnectAttempt: boolean) {
    this.ws = new WebSocket(this.url, this.protocols);

    this.onconnecting();
    this.log('ReconnectingWebSocket', 'attempt-connect', this.url);

    const localWs = this.ws;
    const timeout = setTimeout(() => {
      this.log('ReconnectingWebSocket', 'connection-timeout', this.url);
      this.timedOut = true;
      localWs.close();
      this.timedOut = false;
    }, this.timeoutInterval);

    this.ws.onopen = (event: Event) => {
      clearTimeout(timeout);
      this.log('ReconnectingWebSocket', 'onopen', this.url);
      this.readyState = WebSocket.OPEN;
      reconnectAttempt = false;
      this.onopen(event);
    };

    this.ws.onclose = (event: CloseEvent) => {
      clearTimeout(timeout);
      this.ws = null;
      if (this.forcedClose) {
        this.readyState = WebSocket.CLOSED;
        this.onclose(event);
      } else {
        this.readyState = WebSocket.CONNECTING;
        this.onconnecting();
        if (!reconnectAttempt && !this.timedOut) {
          this.log('ReconnectingWebSocket', 'onclose', this.url);
          this.onclose(event);
        }
        setTimeout(() => {
          this.connect(true);
        }, this.reconnectInterval);
      }
    };
    this.ws.onmessage = (event) => {
      this.log('ReconnectingWebSocket', 'onmessage', this.url, event.data);
      this.onmessage(event);
    };
    this.ws.onerror = (event) => {
      // this.log('ReconnectingWebSocket', 'onerror', this.url, event);
      // this.onerror(event);
    };
  }

  public send(data: any) {
    if (this.ws) {
      this.log('ReconnectingWebSocket', 'send', this.url, data);
      return this.ws.send(data);
    } else {
      throw 'INVALID_STATE_ERR : Pausing to reconnect websocket';
    }
  }

  /**
   * Returns boolean, whether websocket was FORCEFULLY closed.
   */
  public close(): boolean {
    if (this.ws) {
      this.forcedClose = true;
      this.ws.close();
      return true;
    }
    return false;
  }

  /**
   * Additional public API method to refresh the connection if still open (close, re-open).
   * For example, if the app suspects bad data / missed heart beats, it can try to refresh.
   *
   * Returns boolean, whether websocket was closed.
   */
  public refresh(): boolean {
    if (this.ws) {
      this.ws.close();
      return true;
    }
    return false;
  }

  private log(...args: any[]) {
    if (this.debug || ReconnectingWebSocket.debugAll) {
      console.debug.apply(console, args);
    }
  }
}
