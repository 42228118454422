import { Component, ElementRef, OnDestroy, OnInit, Pipe } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ISubscription } from 'rxjs/Subscription';
import { AppComponent } from '../../../app.component';
import { BanService } from '../../../service/ban.service';
import { ChatService } from '../../../service/chat.service';
import { ConversatonsService } from '../../../service/conversatons.service';
import { UserdataService } from '../../../service/userdata.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.less']
})

export class DialogComponent implements OnInit, OnDestroy {
  spinner = true;
  placeholder = false;
  flagPreview;
  // user session
  userSession = localStorage.getItem('userSession');
  // user data
  userData = JSON.parse(localStorage.getItem('user'));

  subscription: ISubscription;

  type = false;
    public reportMess = new FormControl('');
    friendID = localStorage.getItem('friendID');

    dialogID = localStorage.getItem('dialogID');
  dialogFriendID = localStorage.getItem('dialogFriendID');
  dialogFriendAvatar = localStorage.getItem('dialogFriendAvatar');
  dialogFriendOnline = localStorage.getItem('dialogFriendOnline');
  dialogFriendName = localStorage.getItem('dialogFriendName');

  showFriendData = {};
  messageData = [];
  messageText = '';

  messCounter: any;
  messByMeTime: any;
  newLengthMessages = [];
  flagBan: boolean = false;
  hashBan: any;

  openModal = false;
  modalContent = '';
  messageInterval: any;
    private browserLang: any;
    private isNewMessage = 0;
    public flagToScroll = true;
    public message$: any;

    constructor(
      private userdataService: UserdataService,
      private elRef: ElementRef,
      private router: Router,
      private conversationService: ConversatonsService,
      public appComponent: AppComponent,
      private socket: ChatService,
      private translate: TranslateService,
      private ban: BanService
  ) {
      this.showFriendData = [this.dialogFriendAvatar, this.dialogFriendOnline, this.dialogFriendName, this.dialogFriendID];
        if (localStorage.getItem('lang') === 'ru') {
            this.translate.use(this.browserLang = 'ru');
        } else {
            this.translate.use(this.browserLang = 'kz');
        }
  }

  ngOnInit() {
      this.appComponent.currentUrl = 'dialog';

      this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {
        if (udRes['errors']) {
          // console.log('unauthorized!');
            if (localStorage.getItem('preview') === 'true') {
                this.flagPreview = 'true';
            }
            localStorage.clear();
            if (this.flagPreview === 'true') {
                localStorage.setItem('preview', 'true');
            }
            if (this.appComponent.flagLanguage === 'kz') {
                localStorage.setItem('lang', 'kz');
            } else {
                localStorage.setItem('lang', 'ru');
            }
          this.router.navigate(['']);
        } else if (udRes['meta'].user) {
          // Обновляем сессию в Local Storage
          // console.log(udRes);
          this.userSession = udRes['meta'].user.session;
          localStorage.setItem('userSession', this.userSession);

          // user data
          this.userData = udRes['meta'].user;

          // Обновляем пользователя в Local Storage
          localStorage.setItem('user', JSON.stringify(this.userData));

          // Сразу показываем месседж бар
          this.elRef.nativeElement.querySelector('.textarea').scrollIntoView();


          this.messageInterval = setInterval(() => {
              console.log(this.isNewMessage);

              this.conversationService.dialogRequest(this.dialogID, this.userSession).subscribe(dia => {
                  this.flagBan = dia['meta'].friend.ban;

                  if (this.isNewMessage === 0) {
                      this.isNewMessage = dia['data'].length;
                      this.messageData = dia['data'].reverse();
                      this.messCounter = dia['data'].length - 1;
                      this.messCounter = dia['data'].length - 1;
                      this.spinner = false;
                      if (this.messageData.length <= 0) { this.placeholder = true; } else { this.placeholder = false; }

                      if (this.newLengthMessages.length === 0) {
                          this.newLengthMessages.length = this.messageData.length;
                      }
                  }

                  if (this.flagBan) {
                      if (this.newLengthMessages.length === this.messageData.length) {
                          if (this.browserLang === 'ru') {
                              this.hashBan = this.messageData[this.messageData.length - 1].hash;
                              const elemCopy = {
                                  hash: this.messageData[this.messageData.length - 1].hash,
                                  incoming: this.messageData[this.messageData.length - 1].incoming,
                                  message_id: this.messageData[this.messageData.length - 1].message_id,
                                  text: 'Пользователь ' + this.dialogFriendName + ' вас заблокировал, вы не можете отправлять ему сообщения.',
                                  time: this.messageData[this.messageData.length - 1].time,
                                  user_id: this.messageData[this.messageData.length - 1].user_id
                              }
                              this.messageData.push(elemCopy);
                          } else {
                              const elemCopy = {
                                  hash: this.messageData[this.messageData.length - 1].hash,
                                  incoming: this.messageData[this.messageData.length - 1].incoming,
                                  message_id: this.messageData[this.messageData.length - 1].message_id,
                                  text: ' Өкінішке орай, бұл пайдаланушы парақшасын өшірді',
                                  time: this.messageData[this.messageData.length - 1].time,
                                  user_id: this.messageData[this.messageData.length - 1].user_id
                              }
                              this.messageData.push(elemCopy);
                          }
                      }
                  } else {
                      if (this.hashBan === this.messageData[this.messageData.length - 1].hash) {
                          this.messageData.splice(-1, 1);
                          this.hashBan = '';
                      }
                      if (this.isNewMessage !== dia['data'].length) {
                          this.flagToScroll = true;
                          console.log(this.isNewMessage);
                          this.messageData = dia['data'].reverse();
                          this.messCounter = dia['data'].length - 1;
                          this.messCounter = dia['data'].length - 1;
                          this.spinner = false;
                          this.newLengthMessages.length = this.messageData.length;
                          if (this.messageData.length <= 0) {
                              this.placeholder = true;
                          } else {
                              this.placeholder = false;
                          }
                          localStorage.setItem('isEndPipe', 'true');
                      }
                  }

                  this.message$ = this.messageData;
                  const last = Object.keys(this.message$).length - 1;
                  const messPull = this.elRef.nativeElement.getElementsByClassName('message-line') as HTMLCollection;
                  if (last > 0 && this.flagToScroll) {
                      setTimeout( () => {
                              messPull[last].scrollIntoView();
                              this.flagToScroll = false;
                          }, 100
                      );
                  }
              });
          }, 3000);


          // Подписываемся на события сокета
          this.subscription = this.socket.messages.subscribe(wtf => {
            console.log(wtf);
              if ( wtf.event === 'err_banned') {
                  const last = Object.keys(this.messageData).length - 1;
                  if (this.browserLang === 'ru') {
                      this.messageData[last].text = 'Пользователь ' + this.dialogFriendName + '  вас заблокировал, вы не можете отправлять ему сообщения.';
                  } else {
                      this.messageData[last].text = 'Өкінішке орай, бұл пайдаланушы сізді бұғаттады';
                  }
              }
              if ( wtf.event === 'err_removed') {
                  const last = Object.keys(this.messageData).length - 1;
                  if (this.browserLang === 'ru') {
                      this.messageData[last].text = 'Пользователь ' + this.dialogFriendName + ' удален, вы не можете отправлять ему сообщения.';
                  } else {
                      this.messageData[last].text = 'Өкінішке орай, бұл пайдаланушы парақшасын өшірді';
                  }
              }
            if ( wtf.event === 'err_session_required' ) {
              // console.log('WEBSOCKET : нет сессии!');
            }
            if ( wtf.event === 'err_unauthorized' ) {
              // console.log('WEBSOCKET : соединение НЕ установлено!');
            }
            if ( wtf.event === 'success_authorized' ) {
              // console.log('WEBSOCKET : установлено соединение!');
            }
            if ( wtf.event === 'ping' ) {
              this.socket.send({'event': 'pong', 'data': ''});
              // console.log('WEBSOCKET : получен ПИНГ!');
            }
            if ( wtf.event === 'message_sent' ) {
              // console.log('WEBSOCKET : сообщение ' + wtf.data.message_id + ' отправлено!');
            }
            if ( wtf.event === 'message_delivered' ) {
              // console.log('WEBSOCKET : сообщение доставлено!');
            }
            if ( wtf.event === 'message_read' ) {
              // console.log('WEBSOCKET : сообщение прочитано!');
            }
            if ( wtf.event === 'new_message' ) {
              // console.log('WEBSOCKET : получено СООБЩЕНИЕ!');
              // console.log(wtf.data);

              // console.log(wtf.data.message_id);
                console.error(wtf['data']);
              this.socket.send({'event': 'message_read', 'data': {'message_id': wtf.data.message_id}});
              // console.log('Сообщаениe ' + wtf.data.message_id + ' прочитано!');


              // const allmess = this.elRef.nativeElement.getElementsByClassName('dialog-roll-inner')[0].children.length - 1;
              // const newmess = this.elRef.nativeElement.getElementsByClassName('dialog-roll-inner')[0].children[allmess];
              // setTimeout( () => {
              //     newmess.scrollIntoView();
              //   }, 100
              // );
            }
          });

          // console.log(this.dialogFriendID);
          // console.log(this.dialogID);
        }});
  }


  typeActive() {
    this.type = true;
    this.elRef.nativeElement.querySelector('.textarea').focus();
  }
  typeFocusout() {
    // console.log(this.elRef.nativeElement.querySelector('.textarea'));
    if (this.messageText === '') {
      this.type = false;
    } else {
      this.type = true;
    }
  }

  sendMessage($event) {
      if (!this.flagBan) {
      this.type = true;
      this.elRef.nativeElement.getElementsByTagName('textarea')[0].focus();
      this.elRef.nativeElement.getElementsByTagName('textarea')[0].scrollIntoView(true);
      const spaceTest = this.messageText.trim();

      if (spaceTest) {

        // console.log(this.messageText);

        const localID = new Date().getTime();
        const message = {
          'event': 'new_message',
          'data': {
            'friend_id': this.dialogFriendID,
            'local_message_id': localID,
            'text': this.messageText
          }
        };

        // Отправляем сообщение в сокет
        this.socket.send(message);

        // time near new message
        const date = new Date().getTime() / 1000;
        this.messByMeTime = date;

        this.messageData.push({
          'text': this.messageText,
          'time': this.messByMeTime,
          'incoming': false,
          'lid': localID,
          'status': ''
        });

        if (this.messageData.length <= 0) {
          this.placeholder = true;
        } else {
          this.placeholder = false;
        }

            // scroll to new message
            const last = Object.keys(this.messageData).length - 1;
            const messPull = this.elRef.nativeElement.getElementsByClassName('message-line') as HTMLCollection;
            if (last > 0) {
                setTimeout(() => {
                        messPull[last].scrollIntoView();
                    }, 100
                );
            }
        }

        this.messageText = '';

        setTimeout(() => {
          this.elRef.nativeElement.querySelector('.textarea').scrollIntoView(true);
          }, 500
        );

      } else {
      }
  }

  reciprocity($event) {
  }

  sendReport() {
        this.ban.sendReport(this.friendID, this.userSession, this.reportMess.value).subscribe( resRep => {
            if (resRep['meta'].success === true) {
                this.appComponent.isPopupReport = 'hide';
                this.appComponent.isPopupReportSuccess = 'show';
            }
        });
  }

  showModal($event) {
    if ($event.report) {
      if ($event.report === true) {
        this.openModal = true;
        this.modalContent = 'friend report';
      }
    } else if ($event.banConfirm) {
      if ($event.banConfirm === true) {
        this.openModal = true;
        this.modalContent = 'friend ban confirm';
      }
    }
  }

  receiveModalEvents($event) {
    if ($event.close === true) {
      this.openModal = false;
    }
    if ($event.reportMess) {
      this.openModal = false;
      this.ban.sendReport(this.dialogFriendID, this.userSession, $event.reportMess).subscribe( resRep => {
        if (resRep['meta'].success === true) {
          this.openModal = true;
          this.modalContent = 'report successful sent';
        }
      });
    }
    if ($event.ban) {
      if ($event.ban === true) {
        this.openModal = false;
        this.ban.sendToBan(this.dialogFriendID, this.userSession).subscribe( resBan => {
          if (resBan['meta'].success === true) {
            this.openModal = true;
            this.modalContent = 'friend ban';
          }
        });
      }
    }
  }

  ngOnDestroy() {
      clearInterval(this.messageInterval);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}


@Pipe({
  name: 'reverse',
  pure: false
})

export class ReversePipe {
  transform(value) {
    return value.slice().reverse();
  }
}

@Pipe ({
  name: 'timeconvert'
})

export class TimeconvertPipe {
  transform(value) {
    const date = new Date(value * 1000);
    const hours = date.getHours();
    const minutes = '0' + date.getMinutes();
    // let seconds = '0' + date.getSeconds();
    const convertedTime = hours + ':' + minutes.substr(-2);
    return convertedTime;
  }
}


@Pipe ({
    name: 'dateconvert'
})

export class DateconvertPipe {
    private arrDate = [];
    arrMonths;
    transform(value) {
        if (localStorage.getItem('isEndPipe') === 'true') {
            this.arrDate = [];
            localStorage.setItem('isEndPipe', 'false');
        }

        if (localStorage.getItem('lang') === 'ru') {
            this.arrMonths = [
                'Января',
                'Февраля',
                'Марта',
                'Апреля',
                'Мая',
                'Июня',
                'Июля',
                'Августа',
                'Сентября',
                'Октября',
                'Ноября',
                'Декабря'
            ];
        } else {
            this.arrMonths = [
                'Қаңтар',
                'Ақпан',
                'Наурыз',
                'Сәуір',
                'Мамыр',
                'Маусым',
                'Шілде',
                'Тамыз',
                'Қыркүйек',
                'Қазан',
                'Қараша',
                'Желтоқсан'
            ];
        }

        const date = new Date(value * 1000);
        const month = this.arrMonths[date.getMonth()];
        const day = date.getDate();

        const today = new Date();
        const currentDay = today.getDate();
        const currentMonth = this.arrMonths[today.getMonth()];

        if (day.toString().length === 1) {
        }
        const arrDateItem = day + '.' + month;
        const currentDateItem = currentDay + '.' + currentMonth;

        let dateConvert;

        if (arrDateItem === currentDateItem) {
            if (localStorage.getItem('lang') === 'ru') {
                dateConvert = 'Сегодня';
            } else {
                dateConvert = 'Бүгін';
            }
        } else {
            dateConvert = day + ' ' + month;
        }
        if (this.arrDate.length === 0) {
            this.arrDate.push(arrDateItem);
        } else {
            if (this.arrDate.indexOf(arrDateItem) === -1) {
                this.arrDate.push(arrDateItem);
            } else {
                dateConvert = '';
            }
        }

        return dateConvert;
    }
}

@Pipe ({
    name: 'dateconvertMessages'
})

export class DateconvertMessagesPipe {
    private arrDate = [];
    arrMonths;
    transform(value) {
        if (localStorage.getItem('isEndPipe') === 'true') {
            this.arrDate = [];
            localStorage.setItem('isEndPipe', 'false');
        }
        if (localStorage.getItem('lang') === 'ru') {
            this.arrMonths = [
                'Января',
                'Февраля',
                'Марта',
                'Апреля',
                'Мая',
                'Июня',
                'Июля',
                'Августа',
                'Сентября',
                'Октября',
                'Ноября',
                'Декабря'
            ];
        } else {
            this.arrMonths = [
                'Қаңтар',
                'Ақпан',
                'Наурыз',
                'Сәуір',
                'Мамыр',
                'Маусым',
                'Шілде',
                'Тамыз',
                'Қыркүйек',
                'Қазан',
                'Қараша',
                'Желтоқсан'
            ];
        }

        const date = new Date(value * 1000);
        const month = this.arrMonths[date.getMonth()];
        const day = date.getDate();

        const today = new Date();
        const currentDay = today.getDate();
        const currentMonth = this.arrMonths[today.getMonth()];

        if (day.toString().length === 1) {
        }
        const arrDateItem = day + '.' + month;
        const currentDateItem = currentDay + '.' + currentMonth;

        let dateConvert;

        if (arrDateItem === currentDateItem) {
            if (localStorage.getItem('lang') === 'ru') {
                dateConvert = 'Сегодня';
            } else {
                dateConvert = 'Бүгін';
            }
            const dateCurrentTime = new Date(value * 1000);
            const hours = dateCurrentTime.getHours();
            const minutes = '0' + dateCurrentTime.getMinutes();
            const convertedCurrentTime = hours + ':' + minutes.substr(-2);
            return convertedCurrentTime;
        } else {
            dateConvert = day + ' ' + month;
        }
        if (this.arrDate.length === 0) {
            this.arrDate.push(arrDateItem);
        } else {
                this.arrDate.push(arrDateItem);
        }

        return dateConvert;
    }
}

