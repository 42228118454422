import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ISubscription } from 'rxjs/Subscription';
import { AppComponent } from '../../app.component';
import { ChatService } from '../../service/chat.service';
import { ConversatonsService } from '../../service/conversatons.service';
import { UserdataService } from '../../service/userdata.service';


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.less']
})

export class MessagesComponent implements OnInit, OnDestroy {
  spinner = true;

  placeholder = false;

  // user session
  userSession = localStorage.getItem('userSession');
  // user data
  userData = JSON.parse(localStorage.getItem('user'));

  subscription: ISubscription;

  userDialogs: any;
  userDialogsNew: any;

  dialogID: any;
  dialogFriendID: any;
  dialogFriendAvatar: any;
  dialogFriendOnline: any;
  dialogFriendName: any;

  dialogImage: any;
  dialogName: any;
  dialogTime: any;
  dialogMess: any;

  noImage: any;
  newMess: any;
  messageInterval: any;
  isNewMessageDialog: any = 0;
  spinnerLoadMore: any;
  offsetLoadMore: any;
  offsetLoadMoreOld: any;

    private browserLang: any;

    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {
        const scrollHeight = document.documentElement.scrollHeight;
        const clientHeight = document.documentElement.clientHeight;
        const height = scrollHeight - clientHeight;

        if (window.pageYOffset >= height) {


            if (this.offsetLoadMoreOld % 20 === 0) {
                console.warn(this.offsetLoadMoreOld % 20, this.offsetLoadMoreOld);

                this.loadMoreDialogs ();
            }
        }
    }
  constructor(
    private userdataService: UserdataService,
    private conversationService: ConversatonsService,
    private router: Router,
    private translate: TranslateService,
    public appComponent: AppComponent,
    private socket: ChatService,
  ) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
      this.offsetLoadMore = 20;
    this.spinnerLoadMore = false;
    this.appComponent.currentUrl = 'game';
    this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {
      if (udRes['errors']) {
        // console.log('unauthorized!');
      } else if (udRes['meta'].user) {
        // Обновляем сессию в Local Storage
        // console.log(udRes);
        this.userSession = udRes['meta'].user.session;
        localStorage.setItem('userSession', this.userSession);

        // user data
        this.userData = udRes['meta'].user;

        // Обновляем пользователя в Local Storage
        localStorage.setItem('user', JSON.stringify(this.userData));

        // Подписываемся на события сокета
        this.subscription = this.socket.messages.subscribe(wtf => {
          // console.log(wtf);
          // // console.log(wtf.event);
          // // console.log(wtf.data);
          if ( wtf.event === 'err_session_required' ) {
            // console.log('WEBSOCKET : нет сессии!');
          }
          if ( wtf.event === 'err_unauthorized' ) {
            // console.log('WEBSOCKET : соединение НЕ установлено!');
          }
          if ( wtf.event === 'success_authorized' ) {
            // console.log('WEBSOCKET : установлено соединение!');
          }
          if ( wtf.event === 'ping' ) {
            this.socket.send({'event': 'pong', 'data': ''});
            // console.log('WEBSOCKET : получен ПИНГ!');
          }
          if ( wtf.event === 'new_message' ) {
            // console.log('WEBSOCKET : получено СООБЩЕНИЕ!');
            // console.log(wtf.data);
            this.conversationService.conversationsRequest(this.userSession).subscribe(res => {
              // console.log(res);
              this.userDialogs = res['data'];
              // console.log(this.userDialogs);

            });
          }
          if ( wtf.event === 'new_message_from_me' ) {
            // console.log('WEBSOCKET : получено СООБЩЕНИЕ ОТ МЕНЯ!');
            // console.log(wtf.data);
            this.conversationService.conversationsRequest(this.userSession).subscribe(res => {
              // console.log(res);
              this.userDialogs = res['data'];
              // console.log(this.userDialogs);

            });
          }
        });

        // Получаем диалоги пользователя
         this.messageInterval = setInterval(() => {
              this.conversationService.conversationsRequest(this.userSession).subscribe(res => {
                  // Скрываем спиннер
                  this.offsetLoadMoreOld = res['data'].length;
                  console.warn(this.isNewMessageDialog);
                  // if (this.isNewMessageDialog === 0) {
                      this.isNewMessageDialog = res['data'].length;
                      if (res['data']) {
                          this.userDialogs = res['data'];
                          if (this.userDialogs.length === 0) {
                              this.placeholder = true;
                          }
                      }
                      this.spinner = false;
                      if (this.userDialogs.length <= 0) { this.placeholder = true; } else { this.placeholder = false; }
                  // }
                  // if (this.isNewMessageDialog !== res['data'].length) {
                  //     if (res['data']) {
                  //         this.userDialogs = res['data'];
                  //         if (this.userDialogs.length === 0) {
                  //             this.placeholder = true;
                  //         }
                  //     }
                  //     this.spinner = false;
                  //     if (this.userDialogs.length <= 0) { this.placeholder = true; } else { this.placeholder = false; }
                      localStorage.setItem('isEndPipe', 'true');
                  // }
              });
          }, 3000);
      }
    });
  }

  openDialog($event) {
    // console.log($event);
    // console.log($event.id);
    this.dialogID = $event.id;
    this.dialogFriendID = $event.friend.id;
    if ($event.friend.profile.avatar) {
      this.dialogFriendAvatar = $event.friend.profile.avatar.preview_url;
    } else {
      this.dialogFriendAvatar = 'sampleman';
    }
    this.dialogFriendOnline = $event.friend.online;
    this.dialogFriendName = $event.friend.profile.name;

    localStorage.setItem('friendID', this.dialogID);
    localStorage.setItem('dialogID', this.dialogID);
    localStorage.setItem('dialogFriendID', this.dialogFriendID);
    localStorage.setItem('dialogFriendAvatar', this.dialogFriendAvatar);
    localStorage.setItem('dialogFriendOnline', this.dialogFriendOnline);
    localStorage.setItem('dialogFriendName', this.dialogFriendName);
    this.router.navigate(['dialog']);
  }

  loadMoreDialogs () {
      if (this.offsetLoadMoreOld % 20 === 0) {
          this.spinnerLoadMore = true;
          this.conversationService.conversationsRequestLoadMore(this.userSession).subscribe(res => {
              this.offsetLoadMore = res['data'].length;
              this.userDialogs = res['data'];

              this.spinnerLoadMore = false;
          });
      }
  }

  ngOnDestroy() {
      clearInterval(this.messageInterval);
    // this.subscription.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
