import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SubscribeService {

  constructor(private http: HttpClient) { }

  subscribe(session) {
    return this.http.get('/api/v1/user/subscribe', { params: {
      session: session
    }});
  }

}
