export class EditedSettingsData {
  data: {
    profile: {
      name: any;
      nick: any;
      gender: any;
      birthday: any;
      city: any;
    };
    settings: {
      lang: any;
      sms: boolean;
    };
  };
}

export function requestEditedSettingsData(arg1, arg2, arg3, arg4, arg5, arg6, arg7): EditedSettingsData {
  return {
    data: {
      profile: {
        name: arg1,
        nick: arg2,
        gender: arg3,
        birthday: arg4,
        city: arg5
      },
      settings: {
        lang: arg6,
        sms: arg7
      }
    }
  };
}
