import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ISubscription } from 'rxjs/Subscription';
import { AppComponent } from '../../app.component';
import { BanService } from '../../service/ban.service';
import { ChatService } from '../../service/chat.service';
import { ConversatonsService } from '../../service/conversatons.service';
import { FriendService } from '../../service/friend.service';
import { LogoutService } from '../../service/logout.service';
import { UserdataService } from '../../service/userdata.service';
import { SocketService } from '../../service/websocket.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.less']
})
export class TopbarComponent implements OnInit, OnChanges {
  // title
  title = '';

    // show side menu
  menuShow = false;
  newMes = false;

  // show right menu
  rightMenuShow = false;

    // user data
    userData = JSON.parse(localStorage.getItem('user'));

    subscription: ISubscription;


    userName;

  // user edit mode
  edit = false;
  userEdit = false;

  // profile user mode
  profileUser = false;

  // game mode
  game = false;

  // filter mode
  filter = false;

  // notification mode
  noti = false;
  notiEdit = false;

  // settings mode
  settings = false;

  // people mode
  people = false;

  // information mode
  info = false;

  // information mode
  feedBack = false;

  // user session
  userSession = localStorage.getItem('userSession');

  sendPressed = true;

  @Input() editProfileError: any;

  @Input() friendData;
  friendOnline: boolean;
  banned = false;
  flagPreview;
  friendID = localStorage.getItem('friendID');
  dialogID = localStorage.getItem('dialogID');
  dialogFriendID = localStorage.getItem('dialogFriendID');
  dialogFriendAvatar = localStorage.getItem('dialogFriendAvatar');
  dialogFriendName = localStorage.getItem('dialogFriendName');

  @Output() sendEvent = new EventEmitter();
  @Output() sendEventFilter = new EventEmitter();
  @Output() sendEventFeedback = new EventEmitter();

  @Output() gotoMessage = new EventEmitter();

  @Output() reciprocity = new EventEmitter();
  dLike = false;

  @Output() filterNoti = new EventEmitter();

  @Output() sendEventSettings = new EventEmitter();

  @Output() modalContents = new EventEmitter();
    private browserLang: any;
    private userAvatar;
  constructor(
    private userdataService: UserdataService,
    private logoutService: LogoutService,
    private router: Router,
    private location: Location,
    private socket: ChatService,
    public socketNewMess: SocketService,
    private userdata: UserdataService,
    private frienddata: FriendService,
    private translate: TranslateService,
    public appComponent: AppComponent,
    private conversationService: ConversatonsService,
    private ban: BanService
    ) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
    // Подписываемся на события сокета
    // ПОДКЛЮЧАЕМ СОКЕТ

      if (this.appComponent.currentUrl === 'profile-edit-interes' || this.appComponent.currentUrl === 'profile-edit-aboutme') {
          this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {
              if (udRes['errors']) {
                  console.log('unauthorized!');
              } else if (udRes['meta'].user) {
                  // Скрываем спиннер
                  // this.spinner = false;

                  // Обновляем сессию в Local Storage
                  console.log(udRes);
                  this.userSession = udRes['meta'].user.session;
                  localStorage.setItem('userSession', this.userSession);

                  // user data
                  this.userData = udRes['meta'].user;

                  // Обновляем пользователя в Local Storage
                  localStorage.setItem('user', JSON.stringify(udRes['meta'].user));
                  // user name
                  this.userName = this.userData.profile.name;
                  // user avatar
                  if (this.userData.profile.avatar) {
                      this.userAvatar = this.userData.profile.avatar.preview_url + '&w=260';
                  } else {
                      this.userAvatar = './assets/img/icons/sampleman.jpg';
                  }
              }
          });
      }

      this.socket.message$(this.userSession);
    this.socket.messages.subscribe(wtf => {
      console.log(wtf);
      // console.log(wtf.event);
      // console.log(wtf.data);
      if (wtf.event === 'err_session_required') {
        console.log('WEBSOCKET : нет сессии!');
      }
      if (wtf.event === 'err_unauthorized') {
        console.log('WEBSOCKET : соединение НЕ установлено!');
      }
      if (wtf.event === 'success_authorized') {
        console.log('WEBSOCKET : установлено соединение!');
      }
      if (wtf.event === 'ping') {
        this.socket.send({'event': 'pong', 'data': ''});
        console.log('WEBSOCKET : получен ПИНГ!');
      }
      if (wtf.event === 'new_message') {
        console.log('WEBSOCKET : получено СООБЩЕНИЕ!');
        console.log(wtf.data);
        this.newMes = true;
      }
      if (wtf.event === 'new_reciprocity') {
        console.log('WEBSOCKET : получена ВЗАИМНОСТЬ!');
        this.dLike = true;
      }

      if ( wtf.event === 'online' ) {
        console.log('WEBSOCKET : сообщение прочитано!');
        if (wtf.data.user_id === this.dialogFriendID) {
          this.friendOnline = true;
        }
      }
    });

    // TODO
    //   в реал тайме не меняет язык нужен фикс

    this.userdata.userdataRequest(this.userSession).subscribe(newMes => {
      // console.log(newMes['meta'].user.counters.new_messages);
      if (newMes['errors']) {
        console.log(newMes['errors']);
        this.socketNewMess.newMes = false;
      }
      if (newMes['meta'].user.counters.new_messages === true) {
        this.newMes = true;
      } else {
          this.socketNewMess.newMes = false;
      }
    });

    if (this.router.url === '/') {
      console.log('TOPBAR : u r in profile!');
        if (this.appComponent.flagLanguage === 'kz') {
            this.title = 'Менің профилім';
        } else {
            this.title = 'Мой профиль';
        }
      this.edit = true;
      this.userEdit = false;

    } else if (this.router.url === '/profile-edit') {
      console.log('TOPBAR : u r in profile edit!');
        if (this.appComponent.flagLanguage === 'kz') {
            this.title = 'Түзету';
        } else {
            this.title = 'Редактирование';
        }
      this.edit = true;
      this.userEdit = true;

    } else if (this.router.url === '/profile-edit-interes') {
        console.log('TOPBAR : u r in profile edit interes!');
        if (this.appComponent.flagLanguage === 'kz') {
            this.title = '';
        } else {
            this.title = '';
        }
        this.edit = true;
        this.userEdit = true;

    } else if (this.router.url === '/profile-edit-aboutme') {
        console.log('TOPBAR : u r in profile edit aboutme!');
        if (this.appComponent.flagLanguage === 'kz') {
            this.title = '';
        } else {
            this.title = '';
        }
        this.edit = true;
        this.userEdit = true;

    } else if (this.router.url === '/profile-user') {
      console.log('TOPBAR : u r in profile user!');
      this.profileUser = true;
      this.frienddata.frienddataRequest(this.userSession, this.friendID).subscribe( frRes => {
        console.log(frRes)
        if (frRes['data'][0].profile.name) {
          this.title = frRes['data'][0].profile.name;
        };
        if (frRes['data'][0].in_ban === true) {
          this.banned = true;
        }
      });

    } else if (this.router.url === '/game') {
      console.log('TOPBAR : u r in game!');
        if (this.appComponent.flagLanguage === 'kz') {
            this.title = 'Танысу';
        } else {
            this.title = 'Знакомства';
        }
        this.game = true;

    } else if (this.router.url === '/filter') {
      console.log('TOPBAR : u r in filter!');
      this.title = 'Фильтр';
      this.filter = true;

    } else if (this.router.url === '/notifications') {
      console.log('TOPBAR : u r in notifications!');
      if (this.appComponent.flagLanguage === 'kz') {
          this.title = 'Хабарландырулар';
      } else {
          this.title = 'Уведомления';
      }
      this.noti = true;
      this.notiEdit = false;

    } else if (this.router.url === '/notifications-edit') {
      console.log('TOPBAR : u r in notifications edit!');
        if (this.appComponent.flagLanguage === 'kz') {
            this.title = 'Хабарландырулар фильтрі';
        } else {
            this.title = 'Фильтр уведомлений';
        }
      this.noti = true;
      this.notiEdit = true;
      console.log(this.editProfileError);

    } else if (this.router.url === '/messages') {
        if (this.appComponent.flagLanguage === 'kz') {
            this.title = 'Хабарламалар';
        } else {
            this.title = 'Сообщения';
        }
    } else if (this.router.url === '/dialog') {
      console.log('TOPBAR : u r in dialog!');
      console.log(this.friendData);
      if (this.friendData['1'] === 'true') {
        this.friendOnline = true;
      } else if (this.friendData['1'] === 'false') {
        this.friendOnline = false;
      }
      if (this.friendData['0'] === 'sampleman') {
        this.friendData['0'] = './assets/img/icons/sampleman.jpg';
      }

    } else if (this.router.url === '/settings') {
        if (this.appComponent.flagLanguage === 'kz') {
            this.title = 'Баптаулар';
        } else {
            this.title = 'Настройки';
        }
      console.log('TOPBAR : u r in settings!');
      this.settings = true;

    } else if (this.router.url === '/people') {
      if (localStorage.getItem('topbarTitle') === 'like') {
          if (this.appComponent.flagLanguage === 'kz') {
              this.title = 'Сізге ұнағандар';
          } else {
              this.title = 'Вам понравились';
          }
      } else if (localStorage.getItem('topbarTitle') === 'ban') {
          if (this.appComponent.flagLanguage === 'kz') {
              this.title = 'Бұғатталғандар';
          } else {
              this.title = 'Заблокированные';
          }
      }
      console.log('TOPBAR: u r in people!');
      this.people = true;

    } else if (this.router.url === '/info') {
        if (this.appComponent.flagLanguage === 'kz') {
            this.title = 'Ақпарат';
        } else {
            this.title = 'Информация';
        }
      console.log('TOPBAR: u r in information!');
      this.info = true;
    } else if (this.router.url === '/feedback') {
        if (this.appComponent.flagLanguage === 'kz') {
            this.title = 'Кері байланыс';
        } else {
            this.title = 'Обратная связь';
        }
        console.log('TOPBAR: u r in information!');
        this.feedBack = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  showMenu() {
    if (this.menuShow === true) {
      this.menuShow = false;
    } else {
      this.menuShow = true;
    }
  }

  hideMenu() {
    if (!this.menuShow === true) {
      this.menuShow = true;
    } else {
      this.menuShow = false;
    }
  }

  showRightMenu() {
    if (this.rightMenuShow === true) {
      this.rightMenuShow = false;
    } else {
      this.rightMenuShow = true;
    }
  }


  makeReport() {

  }

  openReportPopup() {
      this.rightMenuShow = false;
      if (this.appComponent.isPopupReport === 'show') {
          this.appComponent.isPopupReport = 'hide';
      } else {
          this.appComponent.isPopupReport = 'show';
      }
  }

  closeAllPopup () {
      this.appComponent.isPopupReport = 'hide';
      this.rightMenuShow = false;
  }

  sendToBan() {
      this.ban.sendToBan(this.friendID, this.userSession).subscribe( resRep => {
          if (resRep['meta'].success === true) {
              console.log(resRep['meta']);
              this.appComponent.isPopupReportBanned = 'show';
          }
      });
    this.rightMenuShow = false;
    this.banned = true;
  }

  unBan() {
      this.ban.sendToUnBan(this.friendID, this.userSession).subscribe( resRep => {
          if (resRep['meta'].success === true) {
              console.log(resRep['meta']);
              this.appComponent.isPopupReportUnbanned = 'show';
          }
      });
    this.rightMenuShow = false;
    this.banned = false;
  }

  logout() {
    this.logoutService.logout(this.userSession).subscribe(logRes => {
      if (logRes['errors']) {
          if (localStorage.getItem('preview') === 'true') {
              this.flagPreview = 'true';
          }
          localStorage.clear();
          if (this.flagPreview === 'true') {
              localStorage.setItem('preview', 'true');
          }
          if (this.appComponent.flagLanguage === 'kz') {
              localStorage.setItem('lang', 'kz');
          } else {
              localStorage.setItem('lang', 'ru');
          }
        this.router.navigate(['']);
        console.log('logout success! also, with some error:', logRes['errors']);
        window.location.reload();

      } else if (logRes['meta'].success === true) {
          if (localStorage.getItem('preview') === 'true') {
              this.flagPreview = 'true';
          }
          localStorage.clear();
          if (this.flagPreview === 'true') {
              localStorage.setItem('preview', 'true');
          }
          if (this.appComponent.flagLanguage === 'kz') {
              localStorage.setItem('lang', 'kz');
          } else {
              localStorage.setItem('lang', 'ru');
          }
        this.router.navigate(['']);
        console.log('logout success!');
        window.location.reload();
      }
    });
  }

  stepBack(): void {
      if (this.appComponent.currentUrl === 'profile-edit') {
          this.router.navigate(['']);
      } else {
          this.location.back();
      }
   }


  editProfile() {
    this.router.navigate(['profile-edit']);
  }

  sendEdited() {
    this.sendEvent.emit(this.sendPressed);
  }

  sendFilter() {
    this.sendEventFilter.emit(this.sendPressed);
  }

  sendFeedback() {
    this.sendEventFeedback.emit(this.sendPressed);
  }

  filters() {
    this.router.navigate(['filter']);
  }

  editNoti() {
    this.router.navigate(['notifications-edit']);
  }

  sendEditedNoti() {
    this.filterNoti.emit(this.sendPressed);
  }

  doubleLike() {
    this.reciprocity.emit(this.dLike);
  }

  goToUserPage() {
    localStorage.setItem('friendID', this.friendData[3]);
    this.router.navigate(['profile-user']);
  }

  sendSettings() {
    this.sendEventSettings.emit(this.sendPressed);
  }

}
