import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NotificatorService {

  constructor(
    private http: HttpClient
  ) { }

  getMyNoti(session, types) {
    return this.http.get('/api/v1/user/notifications', {params: {
        session: session,
        limit: '100',
        types: types
    }});
  }

  getMyLikes(session) {
    return this.http.get('/api/v1/user/notifications', {params: {
      session: session,
      limit: '100',
      types: '0'
    }});
  }

  getMyBan(session) {
    return this.http.get('/api/v1/user/banned', {params: {
      session: session,
      limit: '100'
    }});
  }

}
