import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { UserdataService } from '../../service/userdata.service';
import { UsereditService } from '../../service/useredit.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.less']
})
export class FilterComponent implements OnInit {
  spinner = true;
  // user session
  userSession = localStorage.getItem('userSession');
  // user data
  userData = JSON.parse(localStorage.getItem('user'));

  filterGenderVal = '';
  filterAgeFrom: number;
  filterAgeTo: number;
  filterNearby: boolean;


  // range slider
  rangeSlider = [18, 99];
  rangeSliderCurVal: any;
  rangeSliderCurValMin = 18;
  rangeSliderCurValMax = 99;
    private browserLang: any;



  constructor(private userdataService: UserdataService, private usereditService: UsereditService,
              private translate: TranslateService,
              public appComponent: AppComponent, private router: Router) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
      this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {
        if (udRes['errors']) {
          console.log('errors!', udRes);
        } else if (udRes['meta'].user) {
          // Обновляем сессию в Local Storage
          console.log(udRes);
          this.userSession = udRes['meta'].user.session;
          localStorage.setItem('userSession', this.userSession);

          // Обновляем пользователя в Local Storage
          this.userData = udRes['meta'].user;
          localStorage.setItem('user', JSON.stringify(this.userData));

          // Скрываем спиннер
          this.spinner = false;

          // диапазон поиска по полу
          if (this.userData.filter.gender === 0) {
            this.filterGenderVal = '0';
          } else  if (this.userData.filter.gender === 1) {
            this.filterGenderVal = '1';
          } else  if (this.userData.filter.gender === 2) {
            this.filterGenderVal = '2';
          }

          // диапазон поиска по возрасту
          this.filterAgeFrom = this.userData.filter.age.from;
          this.filterAgeTo = this.userData.filter.age.to;
          this.rangeSlider = [this.filterAgeFrom, this.filterAgeTo];
          this.rangeSliderCurValMin = this.filterAgeFrom;
          this.rangeSliderCurValMax = this.filterAgeTo;

          // диапазон поиска по местоположению
          this.filterNearby = this.userData.filter.nearby;

        }
      });
  }

  sliderOnChange($event) {
    this.rangeSliderCurVal = $event;
    this.rangeSliderCurValMin = this.rangeSliderCurVal[0];
    this.rangeSliderCurValMax = this.rangeSliderCurVal[1];
  }
  togglerChange($event) {
    console.log($event.checked);
    this.filterNearby = $event.checked;
  }

  sendNewProfileData($event) {
    console.log($event);

    console.log(this.filterAgeFrom);
    console.log(this.filterAgeTo);
    if ($event === true) {
      this.usereditService.sendFilter(this.userSession, parseInt(this.filterGenderVal), this.rangeSliderCurValMin, this.rangeSliderCurValMax, this.filterNearby).subscribe( res => {
        // Обновляем сессию в Local Storage
        this.userSession = res['meta'].user.session;
        localStorage.setItem('userSession', this.userSession);
        // Обновляем пользователя в Local Storage
        this.userData = res['meta'].user;
        localStorage.setItem('user', JSON.stringify(this.userData));

        if (res['meta'].success === true) {
          this.router.navigate(['game'])

        }
      });
    }
  }

}
