export class FeedbackData {
  data: {
    message: any
  };
}

export function requestMessageData(arg1): FeedbackData {
  return {
    data: {
      message: arg1
    }
  };
}
