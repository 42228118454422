export class LoginData {
  data: {
    phone: any;
    password: any;
  };
}

export function requestLoginData(arg1, arg2): LoginData {
  return {
    data: {
      phone: arg1,
      password: arg2
    }
  };
}
