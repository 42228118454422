import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { ConversatonsService } from '../../service/conversatons.service';
import { GameService } from '../../service/game.service';
import { UserdataService } from '../../service/userdata.service';
import { UsereditService } from '../../service/useredit.service';


@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.less']
})

export class GameComponent implements OnInit, AfterViewInit {
  spinner = true;
  likePressed = false;
  dislikePressed = false;
  userName;
  userDialogs;
  dialogID: any;
  dialogFriendID: any;
  dialogFriendAvatar: any;
  dialogFriendOnline: any;
  dialogFriendName: any;

  // user session
  userSession = localStorage.getItem('userSession');
  // user data
  userData = JSON.parse(localStorage.getItem('user'));

  // user cards
  cardRoll: any;
  // card counter
  cardCounter = 0;
  // card received counter
  cardCounterReceived: number;
  // current card
  cardCurrent: any;
  cardCurrentUrl = '';
  cardCurrentName = '';
  cardCurrentAge = '';
  cardCurrentID = '';
  cardCurrentcardPhotos: any;

  LAZYcardCurrentName = '';
  LAZYcardCurrentAge = '';
  LAZYcardCurrentcardPhotos: any;

  cardCurrentReciprocity;
  cardCurrentUrlReciprocity;
  cardCurrentNameReciprocity;

  usersNotFound = false;

  screenHeight: any;
  photoHeight: number;

  screenW: number;
  boundPhoto = 1;
    endOffset = { x: 0, y: 0 };
    movingOffset = { x: 0, y: 0 };

  openModal = false;
  modalContent = '';
    filters = 'hide';
    private browserLang: any;

    inBounds = true;
    edge = {
        top: false,
        bottom: false,
        left: true,
        right: true
    };
    outOfBounds = {
        top: false,
        bottom: false,
        right: true,
        left: true
    };

    position = {
        x: 0,
        y: 0
    }


// filters
    filterGenderVal = '';
    filterAgeFrom: number;
    filterAgeTo: number;
    filterNearby: boolean;
    cardsLazyNotFound: boolean;
    premission: any;
    boundActive: any;
    defaulTransition: boolean;
    coefficientForBound: any;
    coefficientForRotate: any;
    degreeForRotate: any;
    zIndexWrapper: any;
    flagLike: any;
    flagDislike: any;
    coordsStyle: any;
    draggable = true;


    // range slider
    rangeSlider = [18, 99];
    rangeSliderCurVal: any;
    rangeSliderCurValMin = 18;
    rangeSliderCurValMax = 99;

    // Нахождение склонения существительного после возраста пользователя
  checkAge(userAge, agePostfix) {
    const cases = [2, 0, 1, 1, 1, 2];
    return userAge + ' ' + agePostfix[ (userAge % 100 > 4 && userAge % 100 < 20) ? 2 : cases[(userAge % 10 < 5) ? userAge % 10 : 5] ];
  }

  constructor(
    private userdataService: UserdataService,
    private gameService: GameService,
    public  router: Router,
    public appComponent: AppComponent,
    private usereditService: UsereditService,
    private conversationService: ConversatonsService,
    private translate: TranslateService,

    private elRef: ElementRef
  ) {
    this.screenHeight = (window.screen.height);
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
      this.zIndexWrapper = 20;
      this.degreeForRotate = 0;
    /*dragAndDrop for game*/
    if (window.innerWidth >= 720) {
        this.coefficientForBound = 180;
    } else {
        this.coefficientForBound = window.innerWidth / 100 * 30;
    }

    /*rotate cofficient*/
    if (window.innerWidth >= 720) {
        this.coefficientForRotate = 15 / (window.innerWidth / 2);
    } else {
        this.coefficientForRotate = 5 / (window.innerWidth / 2);
    }

    this.spinner = true;
    this.usersNotFound = false;
    this.appComponent.isPopupPremisiion = 'hide';
    if (this.appComponent.currentUrl !== 'game') {
        this.onInitForFilter ();
    }
      this.appComponent.currentUrl = 'game';

      if (!this.userSession || !this.userData.profile.name) {
      this.router.navigate(['']);
    } else {
      this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {
        if (udRes['errors']) {
        } else if (udRes['meta'].user) {
          // Обновляем сессию в Local Storage
          this.userSession = udRes['meta'].user.session;
          localStorage.setItem('userSession', this.userSession);

          // user data
          this.userData = udRes['meta'].user;

          // Обновляем пользователя в Local Storage
          localStorage.setItem('user', JSON.stringify(this.userData));


          // ========


          this.gameService.getPlayers(this.userSession).subscribe( res => {
            this.cardRoll = '';

            if (res['data']) {
                this.cardRoll = res['data'];
            }

            this.cardCounterReceived = this.cardRoll.length;

            if (this.cardRoll.length === 0) {
                // По вашему запросу никто не найден :С Измените параметры поиска и попробуйте снова!
                this.spinner = false;
                this.usersNotFound = true;
            } else if (this.cardRoll.length === 1) {
                  this.usersNotFound = false;

                  this.photoHeight = 100;

                  this.screenW = this.elRef.nativeElement.querySelector('.pg-game').offsetWidth;

                  // Отрисовка текущей карточки
                  this.cardCurrent = this.cardRoll[0];
                  this.cardCurrentUrl = this.cardCurrent.profile.avatar.url;
                  this.cardCurrentName = this.cardCurrent.profile.name;
                  this.cardCurrentAge = this.cardCurrent.profile.age;
                  if (this.appComponent.flagLanguage === 'kz') {
                      this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['Жас', 'Жас', 'Жас']);
                  } else {
                      this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['год', 'года', 'лет']);
                  }
                  this.cardCurrentID = this.cardCurrent.id;

                  this.cardCurrentcardPhotos = this.cardRoll[0].profile.photos;

                  for (let i = 0; i < this.cardCurrentcardPhotos.length; i++) {
                      this.cardCurrentcardPhotos[i].accsessRight = true;
                      if (this.userData.permissions === 1) {
                          this.cardCurrentcardPhotos[i].accsessRight = false;
                          this.cardCurrentcardPhotos[0].accsessRight = true;
                      }
                  }
                  this.spinner = false;
                  this.cardsLazyNotFound = false;
              } else {
                  this.cardsLazyNotFound = true;

              // this.photoHeight = (this.screenHeight * 68) / 100;

              this.photoHeight = 100;

              this.screenW = this.elRef.nativeElement.querySelector('.pg-game').offsetWidth;

                // Отрисовка текущей карточки
              this.cardCurrent = this.cardRoll[0];
              this.cardCurrentUrl = this.cardCurrent.profile.avatar.url;
              this.cardCurrentName = this.cardCurrent.profile.name;
              this.cardCurrentAge = this.cardCurrent.profile.age;
              if (this.appComponent.flagLanguage === 'kz') {
                  this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['Жас', 'Жас', 'Жас']);
              } else {
                  this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['год', 'года', 'лет']);
              }
              this.cardCurrentID = this.cardCurrent.id;

              this.cardCurrentcardPhotos = this.cardRoll[0].profile.photos;

                if (this.cardRoll[1]) {
                    this.LAZYcardCurrentName = this.cardRoll[1].profile.name;
                    this.LAZYcardCurrentAge = this.cardRoll[1].profile.age;
                    this.LAZYcardCurrentcardPhotos = this.cardRoll[1].profile.photos;
                }

              for (let i = 0; i < this.cardCurrentcardPhotos.length; i++) {
                this.cardCurrentcardPhotos[i].accsessRight = true;
                if (this.userData.permissions === 1) {
                  this.cardCurrentcardPhotos[i].accsessRight = false;
                  this.cardCurrentcardPhotos[0].accsessRight = true;
                }
              }

              this.spinner = false;
            }

          });

          // =======

          if (this.userData.permissions === 2) {
              this.userdataService.userdataRequest(this.userSession).subscribe(udRes2 => {
                  if (udRes2['errors']) {

                  } else if (udRes2['meta'].user) {
                      // user data
                      this.userData = udRes2['meta'].user;
                      // user name
                      this.userName = this.userData.profile.name;
                      this.appComponent.isPopupPremisiion = 'show';
                  }
                  this.spinner = false;
              });

          } else if (this.userData.permissions === 0) {
            // this.modalContent = '';
            this.openModal = false;
          }
        }
      });
    }

      this.onInitForFilter ();
  }

  onDragEnd(event) {

    this.defaulTransition = false;
    this.zIndexWrapper = 0;
    this.coordsStyle = event.style.transform;
  }

  onDragStart(event) {
      this.defaulTransition = true;
  }

  onMoving(event) {
    this.zIndexWrapper = 999;
    this.movingOffset.x = event.x;
    this.movingOffset.y = event.y;
    this.endOffset.x = event.x;
    this.endOffset.y = event.y;

    if (this.movingOffset.x >= this.coefficientForBound) {
        this.flagLike = true;
    } else {
        this.flagLike = false;
    }

    if (this.movingOffset.x <= -this.coefficientForBound) {
        this.flagDislike = true;
    } else {
        this.flagDislike = false;
    }

    if (this.degreeForRotate <= 30 ) {
        this.degreeForRotate = this.coefficientForRotate * this.movingOffset.x;
    } else {
        this.degreeForRotate = 30;
    }
  }

    checkEdge(event) {
  }

  onMoveEnd(event) {
      this.position = {x: 0, y: 0};

      if (this.endOffset.x >= this.coefficientForBound) {
          this.setLike();
      }
      if (this.endOffset.x <= -this.coefficientForBound) {
          this.setDislike();
      }

      this.endOffset.x = 0;
      this.endOffset.y = 0;

      this.flagLike = false;
      this.flagDislike = false;
      this.degreeForRotate = 0;
      this.zIndexWrapper = 20;
  }

  onInitForFilter () {
      this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {
          if (udRes['errors']) {
          } else if (udRes['meta'].user) {

              // диапазон поиска по полу
              if (this.userData.filter.gender === 0) {
                  this.filterGenderVal = '0';
              } else  if (this.userData.filter.gender === 1) {
                  this.filterGenderVal = '1';
              } else  if (this.userData.filter.gender === 2) {
                  this.filterGenderVal = '2';
              }

              // диапазон поиска по возрасту
              this.filterAgeFrom = this.userData.filter.age.from;
              this.filterAgeTo = this.userData.filter.age.to;
              this.rangeSlider = [this.filterAgeFrom, this.filterAgeTo];
              this.rangeSliderCurValMin = this.filterAgeFrom;
              this.rangeSliderCurValMax = this.filterAgeTo;

              // диапазон поиска по местоположению
              this.filterNearby = this.userData.filter.nearby;

          }
      });
  }

  filterPopup() {
      // this.appComponent.isPopupFilter

    if (this.appComponent.filters === 'show') {
        this.sendNewProfileData(true);
        this.appComponent.filters = 'hide';
    } else {
        this.appComponent.filters = 'show';

        this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {
            if (udRes['errors']) {
            } else if (udRes['meta'].user) {
                // Обновляем сессию в Local Storage
                this.userSession = udRes['meta'].user.session;
                localStorage.setItem('userSession', this.userSession);

                // Обновляем пользователя в Local Storage
                this.userData = udRes['meta'].user;
                localStorage.setItem('user', JSON.stringify(this.userData));

                // Скрываем спиннер
                this.spinner = false;

                // диапазон поиска по полу
                if (this.userData.filter.gender === 0) {
                    this.filterGenderVal = '0';
                } else  if (this.userData.filter.gender === 1) {
                    this.filterGenderVal = '1';
                } else  if (this.userData.filter.gender === 2) {
                    this.filterGenderVal = '2';
                }

                // диапазон поиска по возрасту
                this.filterAgeFrom = this.userData.filter.age.from;
                this.filterAgeTo = this.userData.filter.age.to;
                this.rangeSlider = [this.filterAgeFrom, this.filterAgeTo];
                this.rangeSliderCurValMin = this.filterAgeFrom;
                this.rangeSliderCurValMax = this.filterAgeTo;

                // диапазон поиска по местоположению
                this.filterNearby = this.userData.filter.nearby;

            }
        });
    }
  }

    sliderOnChange($event) {
        this.rangeSliderCurVal = $event;
        this.rangeSliderCurValMin = this.rangeSliderCurVal[0];
        this.rangeSliderCurValMax = this.rangeSliderCurVal[1];
    }
    togglerChange($event) {
        this.filterNearby = $event.checked;
    }

    sendNewProfileData($event) {
        if ($event === true) {
            this.usereditService.sendFilter(this.userSession, parseInt(this.filterGenderVal), this.rangeSliderCurValMin, this.rangeSliderCurValMax, this.filterNearby).subscribe( res => {
                // Обновляем сессию в Local Storage
                this.userSession = res['meta'].user.session;
                localStorage.setItem('userSession', this.userSession);
                // Обновляем пользователя в Local Storage
                this.userData = res['meta'].user;
                localStorage.setItem('user', JSON.stringify(this.userData));

                if (res['meta'].success === true) {
                    // this.router.navigate(['game'])
                    this.appComponent.filters = 'hide';
                }
            });
        }
        this.ngOnInit();
    }

    openDialogReciprocity () {
        this.spinner = true;

        this.conversationService.conversationsRequest(this.userSession).subscribe(res => {
            // Скрываем спиннер
            if (res['data']) {
                this.userDialogs = res['data'];
                this.dialogID = this.userDialogs[0].id;
                this.dialogFriendID = this.userDialogs[0].friend.id;
                if (this.userDialogs[0].friend.profile.avatar) {
                    this.dialogFriendAvatar = this.userDialogs[0].friend.profile.avatar.preview_url;
                } else {
                    this.dialogFriendAvatar = 'sampleman';
                }
                this.dialogFriendOnline = this.userDialogs[0].friend.online;
                this.dialogFriendName = this.userDialogs[0].friend.profile.name;

                localStorage.setItem('friendID', this.dialogID);
                localStorage.setItem('dialogID', this.dialogID);
                localStorage.setItem('dialogFriendID', this.dialogFriendID);
                localStorage.setItem('dialogFriendAvatar', this.dialogFriendAvatar);
                localStorage.setItem('dialogFriendOnline', this.dialogFriendOnline);
                localStorage.setItem('dialogFriendName', this.dialogFriendName);
                this.spinner = false;
                this.appComponent.isPopupReciprocity = 'hide';
                this.router.navigate(['dialog']);
            }
        });
    }

  setLike() {
    this.likePressed = true;
    this.gameService.sendLike(this.userSession, this.cardCurrentID).subscribe(res => {
      this.spinner = true;
      this.likePressed = false;

      // console.log(res);
      // console.log(res.meta.reciprocity);
        if (this.cardRoll.length === 1) {
            this.spinner = false;
            this.usersNotFound = true;
        }
      if (res['meta'].reciprocity) {
        this.appComponent.isPopupReciprocity = 'show';
          this.spinner = false;
      } else {

          this.cardCounter = this.cardCounter + 1;
          console.log(this.cardCounter);

          if (this.cardCounter <= this.cardCounterReceived - 1) {

              this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;
              this.cardCurrent = this.cardRoll[this.cardCounter];
              this.cardCurrentUrl = this.cardCurrent.profile.avatar.url;
              this.cardCurrentName = this.cardCurrent.profile.name;
              this.cardCurrentAge = this.cardCurrent.profile.age;
              if (this.appComponent.flagLanguage === 'kz') {
                  this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['Жас', 'Жас', 'Жас']);
              } else {
                  this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['год', 'года', 'лет']);
              }
              this.cardCurrentID = this.cardCurrent.id;
              this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;

              if (this.cardRoll[this.cardCounter + 1]) {
                  this.LAZYcardCurrentName = this.cardRoll[this.cardCounter + 1].profile.name;
                  this.LAZYcardCurrentAge = this.cardRoll[this.cardCounter + 1].profile.age;
                  this.LAZYcardCurrentcardPhotos = this.cardRoll[this.cardCounter + 1].profile.photos;
              }

              for (let i = 0; i < this.cardCurrentcardPhotos.length; i++) {
                  this.cardCurrentcardPhotos[i].accsessRight = true;
                  if (this.userData.permissions === 1) {
                      this.cardCurrentcardPhotos[i].accsessRight = false;
                      this.cardCurrentcardPhotos[0].accsessRight = true;
                  }
              }

              this.spinner = false;

              this.elRef.nativeElement.querySelector('.front .photo').scrollIntoView();


          } else if (this.cardRoll.length && this.cardCounterReceived === 0) {
              console.log('error!');
              // По вашему запросу никто не найден :С Измените параметры поиска и попробуйте снова!
              this.spinner = false;
              this.usersNotFound = true;

          } else if (this.cardCounter === this.cardCounterReceived) {
              this.gameService.getPlayers(this.userSession).subscribe(resNew => {
                  console.log(resNew);
                  this.cardRoll = resNew['data'];
                  this.cardCounter = 0;
                  this.cardCounterReceived = this.cardRoll.length;

                  this.spinner = false;

                  if (this.cardCounterReceived === 0) {
                      // По вашему запросу никто не найден :С Измените параметры поиска и попробуйте снова!
                      this.usersNotFound = true;
                  } else {
                      this.cardCounter = 0;
                      this.usersNotFound = false;

                      this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;
                      this.cardCurrent = this.cardRoll[this.cardCounter];
                      this.cardCurrentUrl = this.cardCurrent.profile.avatar.url;
                      this.cardCurrentName = this.cardCurrent.profile.name;
                      this.cardCurrentAge = this.cardCurrent.profile.age;
                      if (this.appComponent.flagLanguage === 'kz') {
                          this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['Жас', 'Жас', 'Жас']);
                      } else {
                          this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['год', 'года', 'лет']);
                      }
                      this.cardCurrentID = this.cardCurrent.id;
                      this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;


                      for (let i = 0; i < this.cardCurrentcardPhotos.length; i++) {
                          this.cardCurrentcardPhotos[i].accsessRight = true;
                          if (this.userData.permissions === 1) {
                              this.cardCurrentcardPhotos[i].accsessRight = false;
                              this.cardCurrentcardPhotos[0].accsessRight = true;
                          }
                      }


                  }


              });
          }
      }
    });
  }

  hideReciprocity () {
      this.appComponent.isPopupReciprocity = 'hide';
      this.spinner = true;
      this.cardCounter = this.cardCounter + 1;

      if (this.cardCounter <= this.cardCounterReceived - 1) {

          this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;
          this.cardCurrent = this.cardRoll[this.cardCounter];
          this.cardCurrentUrl = this.cardCurrent.profile.avatar.url;
          this.cardCurrentName = this.cardCurrent.profile.name;
          this.cardCurrentAge = this.cardCurrent.profile.age;
          if (this.appComponent.flagLanguage === 'kz') {
              this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['Жас', 'Жас', 'Жас']);
          } else {
              this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['год', 'года', 'лет']);
          }
          this.cardCurrentID = this.cardCurrent.id;
          this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;

          if (this.cardRoll[this.cardCounter + 1]) {
              this.LAZYcardCurrentName = this.cardRoll[this.cardCounter + 1].profile.name;
              this.LAZYcardCurrentAge = this.cardRoll[this.cardCounter + 1].profile.age;
              this.LAZYcardCurrentcardPhotos = this.cardRoll[this.cardCounter + 1].profile.photos;
          }

          for (let i = 0; i < this.cardCurrentcardPhotos.length; i++) {
              this.cardCurrentcardPhotos[i].accsessRight = true;
              if (this.userData.permissions === 1) {
                  this.cardCurrentcardPhotos[i].accsessRight = false;
                  this.cardCurrentcardPhotos[0].accsessRight = true;
              }
          }

          this.spinner = false;

          this.elRef.nativeElement.querySelector('.front .photo').scrollIntoView();


      } else if (this.cardRoll.length && this.cardCounterReceived === 0) {
          // По вашему запросу никто не найден :С Измените параметры поиска и попробуйте снова!
          this.spinner = false;
          this.usersNotFound = true;

      } else if (this.cardCounter === this.cardCounterReceived) {
          this.gameService.getPlayers(this.userSession).subscribe(resNew => {
              this.cardRoll = resNew['data'];
              this.cardCounter = 0;
              this.cardCounterReceived = this.cardRoll.length;

              this.spinner = false;

              if (this.cardCounterReceived === 0) {
                  // По вашему запросу никто не найден :С Измените параметры поиска и попробуйте снова!
                  this.usersNotFound = true;
              } else {
                  this.cardCounter = 0;
                  this.usersNotFound = false;

                  this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;
                  this.cardCurrent = this.cardRoll[this.cardCounter];
                  this.cardCurrentUrl = this.cardCurrent.profile.avatar.url;
                  this.cardCurrentName = this.cardCurrent.profile.name;
                  this.cardCurrentAge = this.cardCurrent.profile.age;
                  if (this.appComponent.flagLanguage === 'kz') {
                      this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['Жас', 'Жас', 'Жас']);
                  } else {
                      this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['год', 'года', 'лет']);
                  }
                  this.cardCurrentID = this.cardCurrent.id;
                  this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;


                  for (let i = 0; i < this.cardCurrentcardPhotos.length; i++) {
                      this.cardCurrentcardPhotos[i].accsessRight = true;
                      if (this.userData.permissions === 1) {
                          this.cardCurrentcardPhotos[i].accsessRight = false;
                          this.cardCurrentcardPhotos[0].accsessRight = true;
                      }
                  }


              }


          });
      }
  }

  setDislike() {
      this.dislikePressed = true;
    this.gameService.sendDislike(this.userSession, this.cardCurrentID).subscribe(res => {
      this.spinner = true;
      this.dislikePressed = false;

      this.cardCounter = this.cardCounter + 1;

      if (this.cardCounter <= this.cardCounterReceived - 1) {

        this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;
        this.cardCurrent = this.cardRoll[this.cardCounter];
        this.cardCurrentUrl = this.cardCurrent.profile.avatar.url;
        this.cardCurrentName = this.cardCurrent.profile.name;
        this.cardCurrentAge = this.cardCurrent.profile.age;
        if (this.appComponent.flagLanguage === 'kz') {
            this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['Жас', 'Жас', 'Жас']);
        } else {
            this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['год', 'года', 'лет']);
        }
        this.cardCurrentID = this.cardCurrent.id;
        this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;

        if (this.cardRoll[this.cardCounter + 1]) {
          this.LAZYcardCurrentName = this.cardRoll[this.cardCounter + 1].profile.name;
          this.LAZYcardCurrentAge = this.cardRoll[this.cardCounter + 1].profile.age;
          this.LAZYcardCurrentcardPhotos = this.cardRoll[this.cardCounter + 1].profile.photos;
        }

        for (let i = 0; i < this.cardCurrentcardPhotos.length; i++) {
          this.cardCurrentcardPhotos[i].accsessRight = true;
          if (this.userData.permissions === 1) {
            this.cardCurrentcardPhotos[i].accsessRight = false;
            this.cardCurrentcardPhotos[0].accsessRight = true;
          }
        }

        this.spinner = false;

        this.elRef.nativeElement.querySelector('.front .photo').scrollIntoView();


      } else if (this.cardRoll.length && this.cardCounterReceived === 0) {
        // По вашему запросу никто не найден :С Измените параметры поиска и попробуйте снова!
        this.spinner = false;
        this.usersNotFound = true;

      } else if (this.cardCounter === this.cardCounterReceived) {

        this.gameService.getPlayers(this.userSession).subscribe( resNew => {
          this.cardRoll = resNew['data'];
          this.cardCounter = 0;
          this.cardCounterReceived = this.cardRoll.length;

          this.spinner = false;

          if (this.cardCounterReceived === 0) {
            // По вашему запросу никто не найден :С Измените параметры поиска и попробуйте снова!
            this.usersNotFound = true;
          } else {
            this.cardCounter = 0;
            this.usersNotFound = false;

            this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;
            this.cardCurrent = this.cardRoll[this.cardCounter];
            this.cardCurrentUrl = this.cardCurrent.profile.avatar.url;
            this.cardCurrentName = this.cardCurrent.profile.name;
            this.cardCurrentAge = this.cardCurrent.profile.age;
            if (this.appComponent.flagLanguage === 'kz') {
                this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['Жас', 'Жас', 'Жас']);
            } else {
                this.cardCurrentAge = this.checkAge(this.cardCurrentAge, ['год', 'года', 'лет']);
            }
            this.cardCurrentID = this.cardCurrent.id;
            this.cardCurrentcardPhotos = this.cardRoll[this.cardCounter].profile.photos;

            for (let i = 0; i < this.cardCurrentcardPhotos.length; i++) {
              this.cardCurrentcardPhotos[i].accsessRight = true;
              if (this.userData.permissions === 1) {
                this.cardCurrentcardPhotos[i].accsessRight = false;
                this.cardCurrentcardPhotos[0].accsessRight = true;
              }
            }


          }

        });
      }

    });
  }

  screenWchange($event) {
    this.screenW = this.elRef.nativeElement.querySelector('.pg-game').offsetWidth;
  }

  goToUserPage() {
    localStorage.setItem('friendID', this.cardCurrentID);
    this.router.navigate(['profile-user']);
  }

  ngAfterViewInit() {
  }

}
