import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { requestPhotoData } from '../models/photo.model';

@Injectable()

export class PhotoService {

  constructor(private http: HttpClient) { }

  photoLoad(session, photoData) {
    return this.http.post('/api/v1/user/photo/load', photoData, { params: { session: session } });
  }

  photoSetMain(session, photoId) {
    return this.http.get('/api/v1/user/photo/setAvatar/'+ photoId +'', { params: { session: session } });
  }

  photoRevome(session, photoId) {
    return this.http.get('/api/v1/user/photo/remove/'+ photoId +'', { params: { session: session } });
  }
}
