import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../../app.component';
import { LogoutService } from '../../service/logout.service';
import { RegistrationService } from '../../service/registration.service';
import { UserdataService } from '../../service/userdata.service';
import { UsereditService } from '../../service/useredit.service';

function AgeChecker(control: FormControl) {
  const val = control.value.replace(/[\/]/g, '');
  const valDay = val.charAt(0) + '' + val.charAt(1);
  const valMonth = val.charAt(2) + '' + val.charAt(3);
  const valYear =
    val.charAt(4) + '' + val.charAt(5) + '' + val.charAt(6) + '' + val.charAt(7);

  console.log(val);
  console.log(val.length);

  if (val.length === 8) {

    /// ====================================================================================================================================

    const userInput = '' + valDay + '.' + valMonth + '.' + valYear + '';
    const parts = userInput.split('.');
    const bd = new Date;
    const cd = new Date;
    const cdYear = cd.getFullYear();
    const cdMonth = cd.getMonth() + 1;
    const cdDay = cd.getDate();

    console.log(parts);

    bd.setFullYear(parseInt(parts[2], 10));
    bd.setMonth(parseInt(parts[1], 10) - 1);
    bd.setDate(parseInt(parts[0], 10));

    const bdYear = bd.getFullYear();
    const bdMonth = bd.getMonth() + 1;
    const bdDay = bd.getDate();

    /// ====================================================================================================================================

    console.log('curDateYear', cdYear);
    console.log('curDateMonth', cdMonth);
    console.log('curDateDay', cdDay);

    console.log('BrhDateYear', bdYear);
    console.log('BrhDateMonth', bdMonth);
    console.log('BrhDateDay', bdDay);

    const diffD = cdDay - bdDay;
    let diffM = cdMonth - bdMonth;
    let diffY = cdYear - bdYear;


    // check day
    if (diffD < 0) {
      diffM = diffM - 1;
    }
    if (diffM < 0) {
      diffY = diffY - 1;
    }
    if (diffY < 18 || diffY > 99) {
      return {
        AgeChecker
      };
    }

    /// ====================================================================================================================================
  }
  return null;
}

function ValiDate(control: FormControl) {
  const val = control.value.replace(/[\/]/g, '');
  const valDay = val.charAt(0) + '' + val.charAt(1);
  const valMonth = val.charAt(2) + '' + val.charAt(3);
  const valYear =
    val.charAt(4) + '' + val.charAt(5) + '' + val.charAt(6) + '' + val.charAt(7);

  console.log(val);
  console.log(val.length);

  if (val.length === 8) {

    /// ====================================================================================================================================

    const userInput = '' + valDay + '.' + valMonth + '.' + valYear + '';
    const parts = userInput.split('.');
    const bd = new Date;
    const cd = new Date;

    console.log(parts);

    const inputBDY = parseInt(parts[2], 10);
    const inputBDM = parseInt(parts[1], 10);
    const inputBDD = parseInt(parts[0], 10);

    bd.setFullYear(parseInt(parts[2], 10));
    bd.setMonth(parseInt(parts[1], 10) - 1);
    bd.setDate(parseInt(parts[0], 10));

    const bdYear = bd.getFullYear();
    const bdMonth = bd.getMonth() + 1;
    const bdDay = bd.getDate();

    const timeDiff = Math.abs(cd.getTime() - bd.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    console.log(diffDays);

    if (bdDay === inputBDD && bdMonth === inputBDM && bdYear === inputBDY) {
      console.log('Everything is alright');
    } else {
      return {
        ValiDate
      };
    }
  }
  return null;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent implements OnInit {
  spinner = true;

  // user session
  userSession = localStorage.getItem('userSession');
  Name;
  // user data
  userData = JSON.parse(localStorage.getItem('user'));

  // Форм контрол имени пользователя
  userName = new FormControl('', [
    Validators.required,
    Validators.pattern('^[A-Za-zА-яа-я0-9ёЁЇїІіЄєҐґ -]+$')
  ]);

  // Форм контрол никнэйм пользователя
  userNickName = new FormControl('', [Validators.pattern('^[а-яА-ЯёЁa-zA-Z0-9]+$')]);

  // Форм контрол пола пользователя
  userGender = new FormControl('', [Validators.required]);

  // Форм контрол даты рождения пользователя
  userBdate = new FormControl('', [Validators.required, AgeChecker, ValiDate]);

  userBDateUnix: any;
  bDay: any;
  bMonth: any;
  bYear: any;
  avatar: any;
  currentValueLanguage: any;

  public maskBdate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  // Форм контрол города пользователя
  userCity = new FormControl('', [Validators.required]);

  // Дропдауны для ввода первоначальных даных
  lGenders: any[];
  gender = '';

  lCities: any[];
  city = '';

  lUiLang: any[];
  userUiLang = new FormControl('', [Validators.required]);

  lUiSms: any;
  userlSms = new FormControl('', [Validators.required]);

  modalContent = '';
  openModal = false;
    private browserLang: any;

  constructor(
    private router: Router,
    private userdataService: UserdataService,
    private usereditService: UsereditService,
    private translate: TranslateService,
    public appComponent: AppComponent,
    private outer: LogoutService,
    private options: RegistrationService
  ) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  getErrorMessageUserName() {
    if (this.browserLang === 'ru') {
        return this.userName.hasError('pattern') ? 'Допускаются только буквы, цифры, пробел и тире' : '';
    } else {
        return this.userName.hasError('pattern') ? 'Тек әріптер, цифрлар, бос орын және сызықша енгізуге болады' : '';
    }
  }

  getErrorMessageUserNickName() {
      if (this.browserLang === 'ru') {
          return this.userNickName.hasError('pattern') ? 'Допускаются только буквы и цифры' :
              this.userNickName.hasError('AlreadyExist') ? 'Пользователь с таким ником уже существует' :
                  '';
      } else {
          return this.userNickName.hasError('pattern') ? 'Тек әріптер мен цифлар енгізуге болады' :
              this.userNickName.hasError('AlreadyExist') ? 'Осындай никпен тіркелген пайдаланушы бар' :
                  '';
      }
  }

  getErrorMessageUserBdate() {
      if (this.browserLang === 'ru') {
          return this.userBdate.hasError('required') ? 'Выберите свою дату рождения' :
              this.userBdate.hasError('ValiDate') ? 'Введите корректную дату' :
                  this.userBdate.hasError('AgeChecker') ? 'Вам должно быть от 18 до 99 лет' :
                      '';
      } else {
          return this.userBdate.hasError('required') ? 'Туған күніңіздің датасын енгізіңіз' :
              this.userBdate.hasError('ValiDate') ? 'Дұрыс мезгілді енгізіңіз' :
                  this.userBdate.hasError('AgeChecker') ? 'Сіздің жас ерекшілігіңіз 18 бен 99 жас аралығын қамту қажет' :
                      '';
      }
  }

  ngOnInit() {
    this.appComponent.currentUrl = 'settings';
    this.options.optionsRequest(this.userSession).subscribe( opt => {
      // Скрываем спиннер
      this.spinner = false;

      this.lGenders = opt['data'][0].gender;
      this.lCities = opt['data'][2].city;
      this.lUiLang = opt['data'][14].lang;
      this.lUiSms = opt['meta'].user.settings.sms;
    });

    this.userdataService.userdataRequest(this.userSession).subscribe(udRes => {
      if (udRes['errors']) {
        console.log('unauthorized!');
      } else if (udRes['meta'].user) {
        // Обновляем сессию в Local Storage
        console.log(udRes);
        this.userSession = udRes['meta'].user.session;
        localStorage.setItem('userSession', this.userSession);

        // user data
        this.userData = udRes['meta'].user;
        this.avatar = this.userData.profile.avatar.url;
        this.Name = this.userData.profile.name;


          // Обновляем пользователя в Local Storage
        localStorage.setItem('user', JSON.stringify(udRes['meta'].user));

        const date = new Date(this.userData.profile.birthday * 1000);

        this.bDay = date.getDate();
        this.bMonth = date.getMonth() + 1;
        this.bYear = date.getFullYear();

        console.log(this.bDay, this.bMonth, this.bYear);
        console.log(date);
        console.log(this.userData);
        console.log(this.userData.profile.birthday);

        if (this.bDay < 10) {
          this.bDay =  0 + '' + this.bDay;
        }
        if (this.bMonth < 10) {
          this.bMonth =  0 + '' + this.bMonth;
        }

        const birthDate = this.bDay + '' + this.bMonth + '' + this.bYear;

        this.userName.setValue(this.userData.profile.name);
        this.userNickName.setValue(this.userData.profile.nick);
        this.userGender.setValue(this.userData.profile.gender.id);
        this.userBdate.setValue(birthDate);
        this.userCity.setValue(this.userData.profile.city.id);
        this.userlSms.setValue(this.userData.settings.sms);
        this.userUiLang.setValue(this.userData.settings.lang.id);
          if (localStorage.getItem('lang') === 'kz') {
            // this.userUiLang.setValue(this.userData.settings.lang[0].id);
            // this.currentValueLanguage = this.lUiLang[0].id;

            // this.currentValueLanguage = 0;
        } else {
            // this.userUiLang.setValue(this.userData.settings.lang[1].id);
            // this.currentValueLanguage = this.lUiLang[1].id;

            // this.currentValueLanguage = 1;
        }
      }
    });
  }

  sendNewSettingsData($event) {
    const userInputRawBD = this.userBdate.value.replace(/[\/]/g, '');
    const userRawBDay = userInputRawBD.charAt(0) + '' + userInputRawBD.charAt(1);
    const userRawBMonth = userInputRawBD.charAt(2) + '' + userInputRawBD.charAt(3);
    const userRawBYear =
      userInputRawBD.charAt(4) + '' + userInputRawBD.charAt(5) + '' + userInputRawBD.charAt(6) + '' + userInputRawBD.charAt(7);
    const userInputConvertedBD = Date.parse(userRawBYear + '-' + userRawBMonth + '-' + userRawBDay) / 1000;
    this.userBDateUnix = userInputConvertedBD;
    if (this.userUiLang.value === 3) {
        localStorage.setItem('lang', 'kz');
        this.translate.use(this.browserLang = 'kz');
    } else {
        localStorage.setItem('lang', 'ru');
        this.translate.use(this.browserLang = 'ru');
    }
    if ($event === true) {
      this.usereditService.sendSettingsData(
        this.userSession,
        this.userName.value,
        this.userNickName.value,
        this.userGender.value,
        this.userBDateUnix,
        this.userCity.value,
        this.userUiLang.value,
        this.userlSms.value
      ).subscribe(res => {
        if (res['errors']) {
         // проверка на ошибку существования ника
            if (res['errors'][0].status === 487) {
            this.userNickName.setErrors({'AlreadyExist' : true});
            // this.userNickName.setError('AlreadyExist')
          }

          setTimeout(() => {
              const elementList = document.querySelectorAll('.' + 'error');
              const element = elementList[0] as HTMLElement;
              element.scrollIntoView({ behavior: 'smooth' });
          }, 1000);

        } else if (res['meta']) {
          // Обновляем сессию в Local Storage
          this.userSession = res['meta'].user.session;
          localStorage.setItem('userSession', this.userSession);
          // Обновляем пользователя в Local Storage
          this.userData = res['meta'].user;
          localStorage.setItem('user', JSON.stringify(res['meta'].user));

          if (res['meta'].success === true) {
            this.router.navigate(['']);
          }
        }

      });
    }

  }

  goToYouLike() {
    localStorage.setItem('showYouLike', JSON.stringify({showYouLike : true}));
    localStorage.setItem('showYouBan', JSON.stringify({showYouBan : false}));
    localStorage.setItem('topbarTitle', 'like');
    this.router.navigate(['people']);
  }

  goToYouBan() {
    localStorage.setItem('showYouBan', JSON.stringify({showYouBan : true}));
    localStorage.setItem('showYouLike', JSON.stringify({showYouLike : false}));
    localStorage.setItem('topbarTitle', 'ban');
    this.router.navigate(['people']);
  }

  goToAbout() {
    this.router.navigate(['info/about_service']);
  }

  goToTerms() {
    this.router.navigate(['info/oferta']);
  }

  profileDelete() {
    this.modalContent = 'profileDelete';
    this.openModal = true;
  }

  receiveModalEvents($event) {
    if ($event.close === true) {
      this.openModal = false;
    }
  }

  accountDelete() {
      this.outer.deleteMe(this.userSession).subscribe( del => {
          if (del['meta'].success === true) {
              if (localStorage.getItem('preview') === 'true') {
                  this.appComponent.flagPreview = 'true';
              }
              localStorage.clear();
              if (this.appComponent.flagPreview === 'true') {
                  localStorage.setItem('preview', 'true');
              }
              if (this.appComponent.flagLanguage === 'kz') {
                  localStorage.setItem('lang', 'kz');
              } else {
                  localStorage.setItem('lang', 'ru');
              }
              this.router.navigate(['']);
          }
      });
  }



}
