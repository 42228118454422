import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { OptionsData } from '../models/options.model';
import {requestEditedSettingsData} from '../models/settings.model';
import {requestMessageData} from '../models/feedback.model';

@Injectable()

export class FeedBackService {

  constructor(private http: HttpClient) { }

  sendNewData(session, userDataNew) {
    return this.http.post('/api/v1/user/feedback', userDataNew, { params: {
        session: session
      }});
  }

  sendFeedback(session, message) {
    return this.http.post('/api/v1/user/feedback',
        requestMessageData(message),
      { params: { session: session } });
  }
}
