import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notifications-edit',
  templateUrl: './notifications-edit.component.html',
  styleUrls: ['./notifications-edit.component.less']
})

export class NotificationsEditComponent implements OnInit {

  notification: {
    filterDoubleLike: boolean;
    filterLikeU: boolean;
    filterVisitor: boolean;
  };

  notificationsLS = JSON.parse(localStorage.getItem('notifications'));

  constructor (
    private router: Router
  ) { }

  ngOnInit() {
    if (this.notificationsLS) {
      this.notification = this.notificationsLS;
    } else {
      this.notification = {
        filterDoubleLike : true,
        filterLikeU : true,
        filterVisitor : true
      };
      localStorage.setItem('notifications', JSON.stringify(this.notification));
    }
  }

  toggler1($event) {
    return this.notification.filterLikeU = $event.checked;
  }

  toggler2($event) {
    return this.notification.filterVisitor = $event.checked;
  }

  toggler3($event) {
    return this.notification.filterDoubleLike = $event.checked;
  }

  sendNewNoti($event) {
    if ($event === true) {
      console.log($event);
      localStorage.setItem('notifications', JSON.stringify(this.notification));
      console.log(this.notification);
      this.router.navigate(['notifications']);
    }
  }

}
