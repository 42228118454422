export class FilterData {
  data: {
    filter: {
      gender: any;
      age: {
        from: any;
        to: any;
      };
      nearby: any;
    };
  };
}

export function requestFilterData(arg1, arg2, arg3, arg4): FilterData {
  return {
    data: {
      filter: {
        gender: arg1,
        age: {
          from: arg2,
          to: arg3
        },
        nearby: arg4
      }
    }
  };
}
