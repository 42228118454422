import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { requestLoginData } from '../models/auth.model';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

@Injectable()

export class AuthService implements CanActivate {

  constructor (
    private http: HttpClient,
    private router: Router
  ) { }

  sendPhone(pnoneNum) {
    return this.http.post('/api/v1/user/send-password', {data: {phone: pnoneNum}});
  }

  login(phoneNum, password) {
    return this.http.post('/api/v1/user/login', requestLoginData(phoneNum, password));
  }

  resetPass(pnoneNum) {
    return this.http.post('/api/v1/user/reset-password', {data: {phone: pnoneNum}});
  }

  isAuthorized() {
    if (localStorage.getItem('userSession') && localStorage.getItem('user')) {
      return true;
    } else {
      return false;
    }
  }

  getSession() {
    return localStorage.getItem('userSession');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const authenticated = this.isAuthorized();

    if (!authenticated) {
      //this.router.navigate(['../']);
      window.location.href = '/';
    }

    return authenticated;
  }
}
