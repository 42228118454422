import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { requestGameData } from '../models/game.model';

@Injectable()
export class GameService {

  constructor(private http: HttpClient) { }

  getPlayers(session) {
    return this.http.post('/api/v1/filter', requestGameData(Array), { params: { session: session } });
  }

  sendLike(session, userId) {
    return this.http.get('/api/v1/friend/like/'+ userId +'', { params: { session: session } });
  }

  sendDislike(session, userId) {
    return this.http.get('/api/v1/friend/dislike/'+ userId +'', { params: { session: session } });
  }


}
