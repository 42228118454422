export class EditedData {
  data: {
    message: any;
  }
}

export function dataReportSend(arg1): EditedData {
  return {
    data: {
      message: arg1
    }
  };
}
