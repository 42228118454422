import { Component, OnInit } from '@angular/core';
import {NotificatorService} from '../../service/notificator.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../../app.component';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less']
})
export class NotificationsComponent implements OnInit {
  spinner = true;

  notiAll = [];

  userSession = localStorage.getItem('userSession');

  notification: {
    filterDoubleLike: boolean;
    filterLikeU: boolean;
    filterVisitor: boolean;
  };

  notificationsLS = JSON.parse(localStorage.getItem('notifications'));

  types = [];
  typeString: string;

  dateString = '';
  notiPlaceholder: boolean;
  placeholder = false;
    private browserLang: any;

  constructor(
    private noti: NotificatorService,
    private translate: TranslateService,
    public appComponent: AppComponent,
    private router: Router
  ) {
      if (localStorage.getItem('lang') === 'ru') {
          this.translate.use(this.browserLang = 'ru');
      } else {
          this.translate.use(this.browserLang = 'kz');
      }
  }

  ngOnInit() {
      this.types = [];
      this.spinner = true;
      this.notificationsLS = JSON.parse(localStorage.getItem('notifications'));

      this.notiAll = [];
      this.appComponent.currentUrl = 'game';

      if (this.notificationsLS) {
      this.notification = this.notificationsLS;
    } else {
      this.notification = {
        filterDoubleLike : true,
        filterLikeU : true,
        filterVisitor : true
      };
      localStorage.setItem('notifications', JSON.stringify(this.notification));
    }

    console.log(this.notification);

    if (this.notification.filterDoubleLike === true) {
      this.types.push(3);
    }
    if (this.notification.filterLikeU === true) {
      this.types.push(1);
    }
    if (this.notification.filterVisitor === true) {
      this.types.push(2);
    }

    this.typeString = this.types.toString();
    console.log('TYPESTRING', this.typeString);

    if (!(this.typeString === '')) {
      this.noti.getMyNoti(this.userSession, this.typeString).subscribe(noti => {
        // Скрываем спиннер
        this.spinner = false;
        this.notiAll = noti['data'];
        if (this.notiAll.length === 0) {
          console.log(this.notiAll);
          this.placeholder = true;
        } else {
          this.placeholder = false;
          this.notiPlaceholder = false;
          console.log(this.notiAll);
          console.log(this.types);
          for (let i = 0; i < this.notiAll.length; i++) {
            let serverGivenDays = this.notiAll[i].days;
            const serverGivenTime = this.notiAll[i].time;
            const serverDate = new Date(serverGivenTime * 1000);
            const serverMonth = serverDate.getMonth() + 1;
            const serverDay = serverDate.getDate();
            const serverHours = serverDate.getHours() + 1;
            const serverMin = serverDate.getMinutes() + 1;

            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1;
            const currentDay = currentDate.getDate();
            const currentHours = currentDate.getHours() + 1;
            const currentMin = currentDate.getMinutes() + 1;

            const diffMonth = currentMonth - serverMonth;
            const diffD = currentDay - serverDay;
            let diffH = currentHours - serverHours;
            const diffMin = currentMin - serverMin;

            if (diffMin < 0) {
              diffH = diffH - 1;
            }
            if (diffH < 0) {
              serverGivenDays = serverGivenDays + 1;
            }

            if (diffD >= 0) {
              this.notiAll[i].days = serverGivenDays;
            }

            if (this.notiAll[i].days === 0) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'бүгін';
                } else {
                    this.notiAll[i].dateString = 'Сегодня';
                }
              this.notiAll[i].showTime = true;
            } else if (this.notiAll[i].days === 1) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'Кеше';
                } else {
                    this.notiAll[i].dateString = 'Вчера';
                }
              this.notiAll[i].showTime = true;
            } else if (this.notiAll[i].days === 2) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'Екі күн бұрын';
                } else {
                    this.notiAll[i].dateString = 'Два дня назад';
                }
              this.notiAll[i].showTime = true;
            } else if (this.notiAll[i].days === 3) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'Үш күн бұрын';
                } else {
                    this.notiAll[i].dateString = 'Три дня назад';
                }
              this.notiAll[i].showTime = true;
            } else if (this.notiAll[i].days > 3 && this.notiAll[i].days <= 7) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'бір аптадай уақыт бұрын';
                } else {
                    this.notiAll[i].dateString = 'Около недели назад';
                }
              this.notiAll[i].showTime = false;
            } else if (this.notiAll[i].days > 7 && this.notiAll[i].days <= 14) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'бір аптадан астам уақыт бұрын';
                } else {
                    this.notiAll[i].dateString = 'Больше недели назад';
                }
              this.notiAll[i].showTime = false;
            } else if (this.notiAll[i].days > 14 && this.notiAll[i].days <= 21) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'екі аптадан астам уақыт бұрын';
                } else {
                    this.notiAll[i].dateString = 'Больше двух недель назад';
                }
              this.notiAll[i].showTime = false;
            } else if (this.notiAll[i].days > 260) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'бір айдан астам уақыт бұрын';
                } else {
                    this.notiAll[i].dateString = 'около года назад';
                }
                this.notiAll[i].showTime = false;
            } else if (this.notiAll[i].days > 180) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'бір айдан астам уақыт бұрын';
                } else {
                    this.notiAll[i].dateString = 'около 6 месяцев назад';
                }
                this.notiAll[i].showTime = false;
            } else if (this.notiAll[i].days > 28) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'бір айдан астам уақыт бұрын';
                } else {
                    this.notiAll[i].dateString = 'Больше месяца назад';
                }
                this.notiAll[i].showTime = false;
            } else if (this.notiAll[i].days > 21) {
                if (this.browserLang === 'kz') {
                    this.notiAll[i].dateString = 'бір ай уақыт бұрын';
                } else {
                    this.notiAll[i].dateString = 'Около месяца назад';
                }
              this.notiAll[i].showTime = false;
            }
          }
        }
      });
    } else {
      // Скрываем спиннер
      this.spinner = false;
      this.notiPlaceholder = true;
    }
  }

  filterNotificationPopup() {
      if (this.appComponent.filtersNotification === 'hide') {
          if (this.notificationsLS) {
              this.notification = this.notificationsLS;
          }
          this.appComponent.filtersNotification = 'show';
      } else {
          this.spinner = true;
          localStorage.setItem('notifications', JSON.stringify(this.notification));
          this.ngOnInit();
          this.appComponent.filtersNotification = 'hide';
          this.spinner = false;
      }
  }

    toggler1($event) {
        return this.notification.filterLikeU = $event.checked;
    }

    toggler2($event) {
        return this.notification.filterVisitor = $event.checked;
    }

    toggler3($event) {
        return this.notification.filterDoubleLike = $event.checked;
    }

  goToUserPage($event) {
    localStorage.setItem('friendID', $event);
    localStorage.setItem('dialogID', $event);
    this.router.navigate(['profile-user']);
  }

}
