import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {dataReportSend} from "../models/report.model";

@Injectable()
export class BanService {

  constructor(private http: HttpClient) { }

  sendReport(UID, session, message) {
    return this.http.post('/api/v1/friend/report/' + UID + '', dataReportSend(message), {params: {
      session: session
    }})
  }

  sendToBan(UID, session) {
    return this.http.get('/api/v1/friend/ban/' + UID + '', {params: {
      session: session
    }});
  }

  sendToUnBan(UID, session) {
    return this.http.get('/api/v1/friend/unban/' + UID + '', {params: {
        session: session
      }});
  }

}
