import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class ConversatonsService {
    private coffLoadMessage = 1;

  constructor(private http: HttpClient) { }

  // @Output() dialogIDchange: EventEmitter<boolean> = new EventEmitter();

  conversationsRequest(session) {
      const currentOffset = 20 * this.coffLoadMessage;
      return this.http.get('/api/v1/user/conversations', { params: {
      session: session,
      limit: currentOffset + '',
    }});
  }


    conversationsRequestLoadMore(session) {
      this.coffLoadMessage += 1;
      const currentOffset = 20 * this.coffLoadMessage;
      return this.http.get('/api/v1/user/conversations', { params: {
          session: session,
          limit: currentOffset + ''
      }});
    }

  dialogRequest(dialogID, session) {
    return this.http.get('/api/v1/user/conversations/messages/' + dialogID + '', { params: {
        session: session,
        limit: '10000'
      }});
  }

}
