import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from './service/auth.service';
import { ChatService } from './service/chat.service';
import { HttpApiService } from './service/http-api/http-api.service';
import { UserdataService } from './service/userdata.service';
import { UsereditService } from './service/useredit.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})

export class AppComponent implements OnInit {

    public currentUrl = '';
    userSession: any;
    userData: any;
    // lang$: BehaviorSubject<Language> = new BehaviorSubject<Language>(DEFAULT_LANG);
    // setLang(lang: Language) {
    //     this.lang$.next(this.currentLang); // тут мы поставим
    // }
    modalContent = 'subscribe';
    openModal = false;

    constructor(private auth: AuthService,
                private user: UserdataService,
                private socket: ChatService,
                private router: Router,
                private usereditService: UsereditService,
                private translate: TranslateService,
                private http: HttpClient,
                private api: HttpApiService,
                private elRef: ElementRef) {
        if (localStorage.getItem('lang') === 'ru') {
            this.translate.use(this.browserLang = 'ru');
            this.flagLanguage = 'ru';
            // console.log('RU');
        } else {
            this.translate.use(this.browserLang = 'kz');
            this.flagLanguage = 'kz';
            // console.log('KZ');
        }
    }

    public browserLang: any;
    public flagLanguage: any = 'ru';
    public flagPreview;
    public bodysRu;
    public bodysKz;
    public httpOptions;
    public filters = 'hide';
    public isPopupPremisiion = 'hide';
    public isPopupPhotoWarning = 'hide';
    public isPopupReport = 'hide';
    public isPopupReportSuccess = 'hide';
    public isPopupReportBanned = 'hide';
    public isPopupReportUnbanned = 'hide';
    public isPopupReciprocity = 'hide';
    public filtersNotification = 'hide';
    public isPopupRemove = 'hide';
    public arrDisplayDate = [];
    private subscriptions: Subscription[] = [];

    ngOnInit() {
        if (this.router.url.indexOf('/profile-edit') !== -1) {
            this.currentUrl = 'profile-edit';
        } else {
            this.currentUrl = '';
        }

        // const langSub = this.configService.lang$
        //     .subscribe(() => {
        //         // ...
        //     });
        // this.subscriptions.push(langSub);


        this.bodysRu = {
            'settings': {
                'lang': '0'
            }
        };

        this.bodysKz = {
            'settings': {
                'lang': '3'
            }
        };

        if (localStorage.getItem('lang') === 'ru') {
            this.translate.use(this.browserLang = 'ru');
            this.flagLanguage = 'ru';
            if (localStorage.getItem('userSession') !== '') {
                this.api.post('user/edit?session=' + localStorage.getItem('userSession'), {data: this.bodysRu});
                // console.log('CHANGE RU');
            }
        } else {
            if (localStorage.getItem('userSession') !== '') {
                this.translate.use(this.browserLang = 'kz');
                this.flagLanguage = 'kz';
                this.api.post('user/edit?session=' + localStorage.getItem('userSession'), {data: this.bodysKz});
                // console.log('CHANGE KZ');
            }
        }

        this.elRef.nativeElement.getElementsByClassName('wrapper')[0].scrollIntoView();

        if (this.auth.isAuthorized() === true) {
            // Отправка запроса на сервер для получения пользователя (первичная)
            this.user.userdataRequest(this.auth.getSession()).subscribe(response => {
                // console.log('APP-COMPONENT : ', response);

                if (response['errors']) {
                    // TODO анализ ошибок
                    localStorage.clear();
                    //this.router.navigate(['']);

                    if (this.flagLanguage === 'kz') {
                        localStorage.setItem('lang', 'kz');
                    } else {
                        localStorage.setItem('lang', 'ru');
                    }
                } else if (response['meta']) {

                    // Обновляем сессию в Local Storage
                    this.userSession = response['meta'].user.session;
                    localStorage.setItem('userSession', this.userSession);
                    // Обновляем пользователя в Local Storage
                    this.userData = response['meta'].user;
                    localStorage.setItem('user', JSON.stringify(this.userData));

                    if (this.userData.service.subscribed === false) {
                        if (this.openModal === false) {
                            this.openModal = true;
                        }
                    } else {
                        this.openModal = false;
                    }

                    this.socket.messages.unsubscribe();
                    this.socket.message$(this.auth.getSession());
                    this.socket.messages.subscribe(ws => {
                        // console.log('APP-COMPONENT-WS : ', ws);
                        if (ws.event === 'err_session_required') {
                            // console.log(ws.data);
                            // console.log('WEBSOCKET : нет сессии!');
                        }
                        if (ws.event === 'err_unauthorized') {
                            // console.log(ws.data);
                            // console.log('WEBSOCKET : соединение НЕ установлено!');
                        }
                        if (ws.event === 'success_authorized') {
                            // console.log(ws.data);
                            // console.log('WEBSOCKET : установлено соединение!');
                        }
                        if (ws.event === 'ping') {
                            // console.log(ws.data);
                            this.socket.send({'event': 'pong', 'data': ''});
                            // console.log('WEBSOCKET : получен ПИНГ!');
                        }
                        if (ws.event === 'message_sent') {
                            // console.log(ws.data);
                            // console.log('WEBSOCKET : сообщение ' + ws.data.message_id + ' отправлено!');
                        }
                        if (ws.event === 'message_delivered') {
                            // console.log(ws.data);
                            // console.log('WEBSOCKET : сообщение доставлено!');
                        }
                        if (ws.event === 'message_read') {
                            // console.log(ws.data);
                            // console.log('WEBSOCKET : сообщение прочитано!');
                        }
                        if (ws.event === 'new_message') {
                            // console.log(ws.data);
                            // console.log('WEBSOCKET : получено СООБЩЕНИЕ!');
                        }

                    });
                }
            });

        } else {
            if (localStorage.getItem('preview') === 'true') {
                this.flagPreview = 'true';
            }
            localStorage.clear();
            if (this.flagPreview === 'true') {
                localStorage.setItem('preview', 'true');
            }
            if (this.flagLanguage === 'kz') {
                localStorage.setItem('lang', 'kz');
            } else {
                localStorage.setItem('lang', 'ru');
            }
        }

        // Отправка запроса на сервер каждые 10 секунд для получения пользователя
        setInterval(() => {
            if (this.auth.isAuthorized() === true && (this.router.routerState.snapshot.url !== '/auth')) {
                this.user.userdataRequest(this.auth.getSession()).subscribe(responseUserData => {
                    // console.log('APP-COMPONENT-USERDATA : ', responseUserData);

                    // Обновляем сессию в Local Storage
                    this.userSession = responseUserData['meta'].user.session;
                    localStorage.setItem('userSession', this.userSession);
                    // Обновляем пользователя в Local Storage
                    this.userData = responseUserData['meta'].user;
                    localStorage.setItem('user', JSON.stringify(this.userData));

                    // console.log(responseUserData['meta'].user.service);

                    if (responseUserData['meta'].user.service.subscribed === false) {
                        // if (this.openModal === false) {
                        // this.modalContent = 'subscribe';
                        // this.openModal = true;
                        // }
                        this.router.navigate(['']);
                    } else if (responseUserData['meta'].user.service.paid === false) {
                        // if (this.openModal === false) {
                        // this.modalContent = 'unpaid';
                        // this.openModal = true;
                        // }
                        this.router.navigate(['']);
                    }

                });
            } else {
                this.router.navigate(['']);

            }
        }, 10000 );

        // this.router.events.subscribe((event) => {
        //
        //     if (event instanceof NavigationEnd) {
        //         console.error(event.url);
        //         if (localStorage.getItem('showTerms') || localStorage.getItem('showAbout')) {
        //         this.router.navigate(['information']);
        //         }
        //     }
        //
        // });
    }

    finalStepForUser() {
        // localStorage.setItem('userSession', this.userSession);
        // localStorage.setItem('user', this.userData);
        this.router.navigate(['']);
    }

    receiveModalEvents($event) {
        // console.log('123');
        // console.log($event);
        if ($event.close === true) {
            this.openModal = false;
        }
        if ($event.respond) {
            this.openModal = false;
            if ($event.respond.meta.user) {

                // Обновляем сессию в Local Storage
                this.userSession = $event.respond.meta.user.session;
                localStorage.setItem('userSession', this.userSession);
                // Обновляем пользователя в Local Storage
                localStorage.setItem('user', JSON.stringify($event.respond.meta.user));

                if ($event.respond['meta'].user.service.paid === true && $event.respond['meta'].user.service.subscribed === true) {
                    this.router.navigate(['']);
                } else {
                    setTimeout(() => {
                        this.openModal = true;
                        this.modalContent = 'unpaid';
                    }, 1);
                }

            }
        }
        if ($event.paidRetry) {
            this.openModal = false;
            if ($event.paidRetry['meta'].user) {
                // Обновляем сессию в Local Storage
                this.userSession = $event.paidRetry.meta.user.session;
                localStorage.setItem('userSession', this.userSession);
                // Обновляем пользователя в Local Storage
                localStorage.setItem('user', JSON.stringify($event.paidRetry.meta.user));

                if ($event.paidRetry['meta'].user.service.paid === true) {
                    if (!$event.paidRetry['meta'].user.profile.name) {
                    } else if ($event.paidRetry['meta'].user.profile.name) {
                        // Пользователь существует и он не новый (есть первоначальные данные), роутим на страницу пользователя:
                        this.router.navigate(['']);
                    }
                } else {
                    this.openModal = true;
                    this.modalContent = 'unpaid';
                    setTimeout(() => {
                        this.openModal = true;
                    }, 1);
                }

            }
        }
        if ($event.showAbout) {
            this.openModal = false;
            if ($event.showAbout === true) {
                setTimeout(() => {
                    this.openModal = true;
                    this.modalContent = 'showAbout';
                }, 1);
            }
        }
        if ($event.previewStep1) {
            this.openModal = false;
            if ($event.previewStep1 === true) {
                setTimeout(() => {
                    this.openModal = true;
                    this.modalContent = 'previewStep1';
                }, 1);
            }
        }
        if ($event.showTerms) {
            this.openModal = false;
            if ($event.showTerms === true) {
                setTimeout(() => {
                    this.openModal = true;
                    this.modalContent = 'showTerms';
                }, 1);
            }
        }
        if (($event.close && $event.showSubscribeFail) && (!$event.showTerms || !$event.showAbout)) {
            this.openModal = false;
            if ($event.close === true && $event.showSubscribeFail === true) {
                setTimeout(() => {
                    // this.openModal = true;
                    // this.modalContent = 'subscribe';
                }, 1);
            }
        }
        if (($event.close && $event.showUnpaid) && (!$event.showTerms || !$event.showAbout)) {
            this.openModal = false;
            if ($event.close === true && $event.showUnpaid === true) {
                setTimeout(() => {
                    // this.openModal = true;
                    // this.modalContent = 'unpaid';
                }, 1);
            }
        }
    }

    changeLang() {
        this.bodysRu = {
            'settings': {
                'lang': '0'
            }
        };

        this.bodysKz = {
            'settings': {
                'lang': '3'
            }
        };

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Accept: 'application/json'
            })
        };

        if (localStorage.getItem('lang') === 'kz') {
            localStorage.setItem('lang', 'ru');
            this.translate.use(this.browserLang = 'ru');
            this.flagLanguage = 'ru';
            // console.log('CHANGE KZ');
            this.api.post('user/edit?session=' + this.userSession, {data: this.bodysRu});

            this.user.userdataRequest(this.auth.getSession()).subscribe(responseUserData => {
                // console.log('APP-COMPONENT-USERDATA : ', responseUserData);

                // Обновляем сессию в Local Storage
                this.userSession = responseUserData['meta'].user.session;
                localStorage.setItem('userSession', this.userSession);
                // Обновляем пользователя в Local Storage
                this.userData = responseUserData['meta'].user;
                localStorage.setItem('user', JSON.stringify(this.userData));

                // console.log(responseUserData['meta'].user.service);

                if (responseUserData['meta'].user.service.subscribed === false) {
                    if (this.openModal === false) {
                        this.modalContent = 'subscribe';
                        this.openModal = true;
                    }
                } else if (responseUserData['meta'].user.service.paid === false) {
                    if (this.openModal === false) {
                        this.modalContent = 'unpaid';
                        this.openModal = true;
                    }
                }

            });

        } else {
            localStorage.setItem('lang', 'kz');
            this.translate.use(this.browserLang = 'kz');
            this.flagLanguage = 'kz';
            // console.log('CHANGE RU');
            this.api.post('user/edit?session=' + this.userSession, {data: this.bodysKz});

            this.user.userdataRequest(this.auth.getSession()).subscribe(responseUserData => {
                // console.log('APP-COMPONENT-USERDATA : ', responseUserData);

                // Обновляем сессию в Local Storage
                this.userSession = responseUserData['meta'].user.session;
                localStorage.setItem('userSession', this.userSession);
                // Обновляем пользователя в Local Storage
                this.userData = responseUserData['meta'].user;
                localStorage.setItem('user', JSON.stringify(this.userData));

                // console.log(responseUserData['meta'].user.service);

                if (responseUserData['meta'].user.service.subscribed === false) {
                    if (this.openModal === false) {
                        this.modalContent = 'subscribe';
                        this.openModal = true;
                    }
                } else if (responseUserData['meta'].user.service.paid === false) {
                    if (this.openModal === false) {
                        this.modalContent = 'unpaid';
                        this.openModal = true;
                    }
                }

            });

        }

        window.location.reload();
    }
}
