import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { object_to_url } from '../../shared/functions/object-to-urlparams'

@Injectable()
export class HttpApiService {
  static API_ADDRESS = '/api/v1'
  constructor(private http: HttpClient) {}

  async get<Response>(resource: string, params?, headers?: HttpHeaders) {
    let request_address: string

    request_address = params
      ? params.id
        ? `${HttpApiService.API_ADDRESS}/${resource}/${params['id']}`
        : `${HttpApiService.API_ADDRESS}/${resource}?${object_to_url(params)}`
      : `${HttpApiService.API_ADDRESS}/${resource}`

    return await this.http.get<Response>(request_address, { headers }).toPromise()
  }

  async post<Response>(resource: string, body?) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json'
      })
    }

    return this.http
      .post<Response>(`${HttpApiService.API_ADDRESS}/${resource}`, JSON.stringify(body), httpOptions)
      .toPromise()
  }
}
